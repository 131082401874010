import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { getAuth } from "firebase/auth";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { createContext } from "react";
import sanpai from "../movies/sanpai.mp4";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Typography } from "@mui/material";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useAuthContext } from "../context/ AuthContext";
import torii from "../images/torii.png";
import oonusa from "../images/oonusa.png";
import shelf from "../images/shelf.png";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import Dialog from "@mui/material/Dialog";
import magatama from "../images/magatama.png";
import BottomMenu from "../ Component/Sub/BottomMenu";

//アニメーションを表示
//DialyHomeへ

export default function Load() {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const [user_id, setUser_id] = useState("");
  const [document_id, setDocumentId] = useState("");
  const [magatama_count, setmagatama_Count] = useState(0);
  const [magatamaD, setmagatamaD] = useState(false);

  useLayoutEffect(() => {
    get_baseinfo();
  }, []);

  useEffect(() => {}, [magatama_count]);

  const goDialy = () => {
    navigate("/dialy");
  };

  const get_baseinfo = async () => {
    try {
      const auth = getAuth();
      const current_uid = auth.currentUser.uid;
      setUser_id(current_uid);
      const userRef = collection(db, "account_list");
      let docid = "";
      const q = query(userRef, where("uid", "==", current_uid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setDocumentId(doc.id);
        // console.log("doc id: " + doc.id);
        docid = doc.id;
      });
      GetSanpaiDeta(userRef, docid);
    } catch (e) {
      console.log("error: " + e.message);
      // setAlert(true);
    }
  };

  const togglemagatamaD = (open, index) => {
    setmagatamaD(open);
  };

  const GetSanpaiDeta = async (userRef, docid) => {
    const docRef = doc(db, "account_list", docid);
    const docSnap = await getDoc(docRef);
    const values = docSnap.data();

    if (typeof values["dango"] !== "undefined") {
      //マガタマの数
      setmagatama_Count(values["dango"]);
    }

    // console.log("magatama_count: " + values["dango"]);
    //ここで参拝した日をクエリ

    try {
      let index = 0;
      let sanpai_values = [""];
      const sanpaiRef = collection(db, "account_list", docid, "sanpai");

      const sanpaiSnaps = await getDocs(sanpaiRef);
      sanpaiSnaps.forEach((doc) => {
        sanpai_values[index] = doc.data();
        index += 1;
      });

      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const today_text = year + "年" + month + "月" + day + "日";
      const deta = {
        year: year,
        month: month,
        day: day,
      };

      // console.log("sanpai_values: " + JSON.stringify(sanpai_values));
      let add = [];

      for (let i = 0; i < sanpai_values.length; i++) {
        let stringDate = "";

        if (sanpai_values[0] !== "") {
          const date_value = sanpai_values[i];
          // console.log("date_value: " + date_value);

          stringDate =
            date_value["year"] +
            "年" +
            date_value["month"] +
            "月" +
            date_value["day"] +
            "日";
        }
        // console.log("stringDate: " + stringDate);
        // console.log("today_text: " + today_text);
        if (stringDate !== today_text) {
          //まだ参拝していないなら
          add.push(true);
        } else {
          add.push(false);
        }
      }

      if (!add.includes(false)) {
        //今日の日付をDBに登録
        await addDoc(sanpaiRef, deta);
        //マガタマを取得

        addmagatama(values["dango"], docid);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const addmagatama = async (magatama, docid) => {
    let new_magatama = 1;

    if (typeof magatama === "number") {
      new_magatama = magatama + 1;
    }
    console.log(new_magatama);

    const docRef = doc(db, "account_list", docid);
    const deta = { dango: new_magatama };
    try {
      await updateDoc(docRef, deta);
      setTimeout(() => {
        togglemagatamaD(true);
      }, 2000);
    } catch (e) {
      console.log("error" + e);
    }
  };

  const goSignup = () => {
    navigate("/signup");
  };

  const CustomisedBottomNavigationAction = (props) => (
    <BottomNavigationAction sx={{ minWidth: "70px" }} {...props} />
  );

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"center"}
    >
      <Box sx={{ height: 90 }}></Box>
      <div
        dangerouslySetInnerHTML={{
          __html: `
        <video
          loop
          muted
          autoPlay
          playsinline
          src="${sanpai}"
          width="90%"
        />,
      `,
        }}
      ></div>
      <Box sx={{ height: 30 }}></Box>
      {user == null && (
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"left"}
          sx={{ width: "80%" }}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }} mb={2}>
            アプリに登録して、参拝日数を記録しましょう！
          </Typography>
          <Button
            sx={{ width: "70%", height: 55, backgroundColor: "#ffa94d" }}
            onClick={() => goSignup()}
            variant="contained"
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "white" }}
            >
              新規登録・ログインする
            </Typography>
          </Button>
        </Grid>
      )}
      {user !== null && (
        <Typography sx={{ fontSize: 23, fontWeight: "bold" }}>
          記録しました
        </Typography>
      )}

      <Dialog
        onClose={() => togglemagatamaD(false)}
        open={magatamaD}
        sx={{ width: "100%" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
          textAlign={"left"}
          p={2}
        >
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }} m={4}>
            まがたま を10個獲得！
          </Typography>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            textAlign={"left"}
            width={"50%"}
          >
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              xs={6}
            >
              <img src={magatama} height={"90"}></img>
            </Grid>
            <Grid
              xs={6}
              item
              container
              alignItems="center"
              justifyContent="center"
              textAlign={"left"}
            >
              <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                × 10
              </Typography>
            </Grid>
          </Grid>

          <Box sx={{ height: 20 }}></Box>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => togglemagatamaD(false, 0)}
            sx={{ width: "70%", height: 55 }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              もどる
            </Typography>
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>

      <BottomMenu value={8} />
    </Grid>
  );
}
