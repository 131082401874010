import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Divider, TextField } from "@mui/material";
import { getAuth } from "firebase/auth";
import {
  collection,
  query,
  getDocs,
  addDoc,
  where,
  orderBy,
  limit,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import Unsei from "../../DialyComponent/ Component/Unsei";
import Koyomi from "../../DialyComponent/ Component/Koyomi";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import ScrollTop from "../../helper/ScrollTop.js";
import { useAuthContext } from "../../context/ AuthContext.js";
import Kamidana from "../../ Component/Sub/Kamidana.js";
import DenseAppbar from "../../ Component/DenseAppbar.js";
import oonusa from "../../images/oonusa.png";
import fox from "../../images/fox.png";
import torii from "../../images/torii.png";
import o_shelf from "../../images/o_shelf.png";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import MyGosyuin from "../component/MyGosyuin.js";
import MyKamidana from "../component/MyKamidana.js";
import GosyuinTicket from "../component/GosyuinTicket";
import CircularProgress from "@mui/material/CircularProgress";
import BottomMenu from "../../ Component/Sub/BottomMenu";
import Contact from "../../ Component/Sub/Contact";
import { GetFieldValues } from "../../ Component/QueryHandler/DailyHandler.js";
import { motion } from "framer-motion";

export default function KamidanaHome(props) {
  const { user } = useAuthContext();

  const navigate = useNavigate();

  const [user_id, setUser_id] = useState("");
  const [document_id, setDocumentId] = useState("");
  const [magatama_count, setmagatama_Count] = useState(null);
  const [query_end, setQueryEnd] = useState(false);
  const [loading, setLoading] = useState(true);

  const [db_deta, set_db_deta] = useState(null);
  const [ex_count, set_ex_count] = useState(0);

  useLayoutEffect(() => {
    if (user !== null) {
      GetFirebaseDeta();
    }
  }, []);

  const GetFirebaseDeta = async () => {
    const deta = await GetFieldValues();
    console.log("Firebase Deta" + JSON.stringify(deta));
    set_db_deta(deta);
    set_ex_count(deta["values"][0]["ex"]);
  };

  useLayoutEffect(() => {
    get_baseinfo();
  }, []);

  useEffect(() => {
    if (magatama_count !== null) {
      setLoading(false);
    }

    if (user == null) {
      setLoading(false);
    }
  }, [document_id, magatama_count]);

  const get_baseinfo = async () => {
    try {
      const auth = getAuth();
      const current_uid = auth.currentUser.uid;
      const userRef = collection(db, "account_list");

      let docid = "";
      const q = query(userRef, where("uid", "==", current_uid));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        docid = doc.id;
        setDocumentId(docid);
      });

      const docRef = doc(db, "account_list", docid);

      const docSnaps = await getDoc(docRef);
      const values = docSnaps.data();
      if (typeof values["dango"] !== "undefined") {
        //マガタマの数
        setmagatama_Count(values["dango"]);
      } else {
        setmagatama_Count(0);
      }
      setQueryEnd(true);
    } catch (e) {
      console.log("error: " + e.message);
    }
  };

  const get_magatama_info = async () => {
    console.log("new get_magatama_info was called");
    try {
      const docRef = doc(db, "account_list", document_id);
      const docSnap = await getDoc(docRef);
      const values = docSnap.data();
      if (typeof values["dango"] !== "undefined") {
        //マガタマの数
        setmagatama_Count(values["dango"]);
        console.log("new magatama");
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!loading) {
    return (
      <Grid>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <ScrollTop></ScrollTop>
          <DenseAppbar
            text="御朱印"
            magatama={magatama_count}
            ex_count={ex_count}
          ></DenseAppbar>
          <Box sx={{ height: 30 }}></Box>
          {user !== null && <Kamidana></Kamidana>}
          {/* {user === null && <WelcomeCard></WelcomeCard>} */}

          {/* <Box sx={{ height: 40 }}></Box> */}
          {/* <Grid container justifyContent={"center"}>
          <GosyuinTicket
            magatama_count={magatama_count}
            document_id={document_id}
            get_magatama_info={get_magatama_info()}
          ></GosyuinTicket>
        </Grid> */}

          {/* <Box sx={{ height: 40 }}></Box> */}
          {document_id !== "" && (
            <React.Fragment>
              <MyKamidana document_id={document_id} />
              <MyGosyuin document_id={document_id} />
            </React.Fragment>
          )}

          {document_id == "" && (
            <React.Fragment>
              <MyKamidana document_id={document_id} />
              <MyGosyuin document_id={document_id} />
            </React.Fragment>
          )}

          <Contact />
          <Box sx={{ height: 120 }}></Box>
        </motion.div>
        <BottomMenu value={3} />
      </Grid>
    );
  } else {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress></CircularProgress>

        <BottomMenu value={2} />
      </Box>
    );
  }
}
