import React, { useContext, useEffect, useState, useLayoutEffect } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export default function Family(props) {
  const accounts = props.accounts;
  if (accounts === "") {
    // console.log("accounts" + accounts)
  }

  const [yakudoshiData, setYakudoshiData] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [yaku_text, setYakuText] = useState("");

  useEffect(() => {
    fetchYakudoshiData();
  }, []);

  const fetchYakudoshiData = async () => {
    const year = new Date().getFullYear();
    try {
      const response = await fetch(
        `https://asia-northeast1-yaku-api.cloudfunctions.net/api/yakudoshi/${
          year + 1
        }`,
        {
          mode: "cors", // CORS対応
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Yakudoshi Data:", data);
      setYakudoshiData(data);
    } catch (err) {
      console.log("error: ", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const isYaku = (year, sex) => {
    let birthday = "";

    if (year.includes("年")) {
      birthday = year.slice(0, -1);
    } else {
      birthday = year;
    }

    // console.log(year + " " + selectedmonth + " " + selectedday);
    // birthday = year + "-" + selectedmonth + "-" + selectedday;

    console.log("selectedSex: " + sex);
    console.log("birthday : " + birthday);
    console.log("yakudoshiData : " + yakudoshiData);

    if (sex !== "" && birthday !== "" && yakudoshiData !== "") {
      console.log(sex + " と " + birthday + " で厄年を取得します");
      //まずは方位除けを取得する
      let houi = "";
      const date = new Date(birthday);
      const year = date.getFullYear();
      const happosai = yakudoshiData.happosai;
      const byomon = yakudoshiData.byomon;
      const kimon = yakudoshiData.kimon;
      if (happosai.includes(String(year))) {
        console.log("「八方塞」です");
        houi = "八方塞";
      } else if (byomon.includes(String(year))) {
        console.log("「病門」です");
        houi = "病門";
      } else if (kimon.includes(String(year))) {
        console.log("「鬼門」です");
        houi = "鬼門";
      }
      //性別に応じて厄年を取得する
      let yaku = "";
      const man_preyaku = yakudoshiData.maleYakudoshi.preYakudoshi;
      console.log("man_preyaku: " + man_preyaku);
      const man_honyaku = yakudoshiData.maleYakudoshi.honYakudoshi;
      console.log("man_honyaku: " + man_honyaku);
      const man_postyaku = yakudoshiData.maleYakudoshi.postYakudoshi;
      console.log("man_postyaku: " + man_postyaku);

      const woman_preyaku = yakudoshiData.femaleYakudoshi.preYakudoshi;
      const woman_honyaku = yakudoshiData.femaleYakudoshi.honYakudoshi;
      const woman_postyaku = yakudoshiData.femaleYakudoshi.postYakudoshi;

      if (sex === "男性") {
        if (man_preyaku.includes(String(year))) {
          console.log("男性：「前厄」です");
          yaku = "前厄";
        } else if (man_honyaku.includes(String(year))) {
          console.log("男性：「本厄」です");
          yaku = "本厄";
        } else if (man_postyaku.includes(String(year))) {
          console.log("男性：「後厄」です");
          yaku = "後厄";
        }
      } else if (sex === "女性") {
        if (woman_preyaku.includes(String(year))) {
          console.log("女性：「前厄」です");
          yaku = "前厄";
        } else if (woman_honyaku.includes(String(year))) {
          console.log("女性：「本厄」です");
          yaku = "本厄";
        } else if (woman_postyaku.includes(String(year))) {
          console.log("女性：「後厄」です");
          yaku = "後厄";
        }
      }

      if (houi !== "" && yaku !== "") {
        //方位と厄年にどちらも該当している場合
        console.log(
          `${year}年生まれの${sex}は「${houi}」と「${yaku}」の年です`
        );
        return `${houi}・${yaku}の年`;
      } else if (houi !== "") {
        //方位に該当している場合
        console.log(`${year}年生まれの${sex}は「${houi}」の年です`);
        return `${houi}の年`;
      } else if (yaku !== "") {
        //厄年に該当している場合
        console.log(`${year}年生まれの${sex}は「${yaku}」の年です`);
        return `${yaku}の年`;
      } else {
        console.log("該当なし");
        return "";
      }
    } else {
      return "";
    }
  };

  return (
    <Grid
      mt={2}
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"left"}
    >
      {accounts != "" &&
        accounts.map((data, i) => (
          <Grid key={i} mb={1}>
            <Card sx={{ minWidth: 280, height: 55 }}>
              <CardContent>
                <Grid container justify="flex-start" alignItems="center">
                  <Typography
                    sx={{ fontSize: 18, fontWeight: "bold" }}
                    pl={1}
                    pr={1}
                  >
                    {accounts[i]["name"] + " 様"}
                  </Typography>
                  {isYaku(accounts[i]["birthday"], accounts[i]["sex"]) !==
                    "" && (
                    <Grid>
                      <Typography
                        sx={{
                          fontSize: 14,
                          backgroundColor: "#f7ad6c",
                          fontWeight: "bold",
                          borderRadius: 1,
                          padding: 0.4,
                        }}
                      >
                        {isYaku(accounts[i]["birthday"], accounts[i]["sex"])}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      {accounts == "" && (
        <Box
          sx={{
            width: "90%",
            backgroundColor: "#fffab995",
            p: 2,
            borderRadius: 2,
          }}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            ご家族やご友人を登録して、厄年かどうか確認してみましょう
          </Typography>
        </Box>
      )}
    </Grid>
  );
}
