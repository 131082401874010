import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Avatar from "@mui/material/Avatar";
import yorokobi from "../../images/yorokobi.jpg";
import kitai from "../../images/kitai.jpg";
import kanasii from "../../images/kanasii.jpg";
import konwaku from "../../images/konwaku.jpg";
import Drawer from "@mui/material/Drawer";
import EditComponent from "./EditComponent";
import Dialog from "@mui/material/Dialog";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";

import {
  collection,
  deleteDoc,
  onSnapshot,
  addDoc,
  doc,
  query,
  getDocs,
  where,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  collectionGroup,
} from "firebase/firestore";
import { db, app } from "../../firebase";
import simenawa from "../../images/simenawa.png";
import LogHistory from "../Page/LogHistory";
import ex from "../../images/ex.png";
import Skeleton from "@mui/material/Skeleton";

export default function Loglist(props) {
  const navigate = useNavigate();
  const [dialy_deta, setDialy_deta] = useState(props.dialy_deta);
  // const slice_dialy_deta = dialy_deta.slice(-2);

  const ids_deta = props.ids_deta;

  // const slice_ids_deta = ids_deta.slice(-2);
  const document_id = props.document_id;

  const [day, setDay] = useState("");
  const [context, setContext] = useState("");
  const [feeling, setFeeling] = useState([""]);
  const [omikuji, setOmikuji] = useState([""]);

  const [deleteD, setDeleteD] = useState(false);
  const [confirmD, setConfirmD] = useState(false);

  const [index, setIndex] = useState("");
  const [state, setState] = useState(false);
  const [dialy_index, setDialy_index] = useState(0);

  const [alldialyD, setAlldialyD] = useState(false); //過去の日記を表示するダイアログ

  const get_slice_feelings = () => {
    let feeling_arr = [];
    for (let i = 0; i < dialy_deta.length; i++) {
      if (i === 0) {
        switch (parseInt([dialy_deta[i]["feeling"]])) {
          case 0:
            feeling_arr = [kitai];
            break;
          case 1:
            feeling_arr = [yorokobi];
            break;
          case 2:
            feeling_arr = [konwaku];
            break;
          case 3:
            feeling_arr = [kanasii];
            break;
          default:
            feeling_arr = [""];
          // console.log("default was called");
        }
      } else {
        switch (parseInt([dialy_deta[i]["feeling"]])) {
          case 0:
            feeling_arr.push(kitai);
            break;
          case 1:
            feeling_arr.push(yorokobi);

            break;
          case 2:
            feeling_arr.push(konwaku);

            break;
          case 3:
            feeling_arr.push(kanasii);

            break;
          default:
            feeling_arr.push("");
          // console.log("default was called");
        }
      }
    }
    // const sliced = feeling_arr.slice(-2);
    return feeling_arr;
  };

  const slice_feelings = get_slice_feelings();

  useEffect(() => {
    get_arr();
    get_slice_feelings();
  }, []);

  useLayoutEffect(() => {
    setDialy_deta(props.dialy_deta);
    get_arr();
    get_slice_feelings();
  }, [props.dialy_deta]);

  useEffect(() => {}, [slice_feelings]);

  const toggleDrawer = (open) => {
    setState(open);
  };

  const get_arr = () => {
    if (dialy_deta !== "") {
      let day_arr = [];
      for (let i = 0; i < dialy_deta.length; i++) {
        if (i === 0) {
          day_arr = [dialy_deta[i]["day"]];
        } else {
          day_arr.push(dialy_deta[i]["day"]);
        }
      }
      let context_arr = [];
      let omikuji_arr = [];

      for (let i = 0; i < dialy_deta.length; i++) {
        if (i === 0) {
          context_arr = [dialy_deta[i]["context"]];

          switch (parseInt([dialy_deta[i]["omikuji_arr"]])) {
            case 0:
              omikuji_arr = [0];
              break;
            case 1:
              omikuji_arr = [1];
              break;
            case 2:
              omikuji_arr = [2];
              break;
            case 3:
              omikuji_arr = [3];
              break;
            default:
              omikuji_arr = [""];
          }
        } else {
          context_arr.push(dialy_deta[i]["context"]);

          switch (parseInt([dialy_deta[i]["omikuji_arr"]])) {
            case 0:
              omikuji_arr.push(0);
              break;
            case 1:
              omikuji_arr.push(1);
              break;
            case 2:
              omikuji_arr.push(2);
              break;
            case 3:
              omikuji_arr.push(3);
              break;
            default:
          }
        }
      }
      setDay(day_arr);
      setContext(context_arr);
      setOmikuji(omikuji_arr);
    }
  };

  const onDelete = async () => {
    if (index === 0) {
      const docid = dialy_deta[index];
      const targetdocRef = doc(db, "account_list", document_id, "dialy", docid);
      await deleteDoc(targetdocRef);
    } else if (index === 1) {
      const docid = dialy_deta[index];
      const targetdocRef = doc(db, "account_list", document_id, "dialy", docid);
      await deleteDoc(targetdocRef);
    }
    // console.log("削除しました");
    setDeleteD(false);
    props.get_baseinfo();
  };

  const Delete = (index) => {
    setDeleteD(true);
    setIndex(index);
  };

  const openDrawer = (index) => {
    toggleDrawer(true);

    setDialy_index(index);
  };

  const handleOpen = (index) => {
    // ロックされていたらパスワード表示ダイアログを表示
    if (props.lock) {
      props.toggleUnlockD(true);
    } else {
      openDrawer(index);
    }
  };

  const OpenLogHistroy = () => {
    if (props.lock) {
      props.toggleUnlockD(true);
    } else {
      ToggleAllDialyD(true);
    }
  };

  const ToggleAllDialyD = (open) => {
    //過去の日記を表示するダイアログの表示を管理
    setAlldialyD(open);
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"left"}
    >
      <Box sx={{ height: 25 }}></Box>

      {dialy_deta[0] !== "" && (
        <Grid sx={{ width: "90%" }}>
          {dialy_deta.map((deta, i) => (
            <Grid key={i}>
              <Card sx={{ width: "100%" }}>
                <CardActionArea onClick={() => handleOpen(i)}>
                  {props.query_end && (
                    <Grid>
                      {!props.lock && (
                        <Grid p={3}>
                          <Grid
                            container
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Typography sx={{ fontSize: 14 }} variant="body2">
                              {dialy_deta[i]["day"]}
                            </Typography>
                            <Box sx={{ width: "100%" }} mt={1.5}>
                              <Typography
                                noWrap={true}
                                sx={{ mt: 1.1, fontSize: 15 }}
                                variant="body2"
                              >
                                {dialy_deta[i]["context"]}
                              </Typography>
                            </Box>
                          </Grid>
                          {/* <Grid item xs={0.5}>
                              <Box></Box>
                            </Grid>
                            <Grid item xs={2.5}>
                              {slice_feelings[i] !== "" && (
                                <Avatar
                                  src={slice_feelings[i]}
                                  style={{
                                    border: "0.1px solid lightgray",
                                  }}
                                  sx={{ width: 50, height: 50 }}
                                />
                              )}
                            </Grid> */}
                        </Grid>
                      )}
                      {props.lock && (
                        <Grid sx={{ width: "100%" }}>
                          <Grid
                            container
                            alignItems="left"
                            justify="center"
                            direction="column"
                            textAlign={"left"}
                            mt={2}
                          >
                            <Typography
                              ml={2}
                              sx={{ fontSize: 14 }}
                              variant="body2"
                            >
                              {dialy_deta[i]["day"]}
                            </Typography>
                          </Grid>

                          <Grid
                            container
                            alignItems="center"
                            justify="center"
                            direction="column"
                            mt={1}
                          >
                            <img style={{ width: "100%" }} src={ex} />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </CardActionArea>
              </Card>
              <Box sx={{ height: 20 }}></Box>
            </Grid>
          ))}

          {!props.query_end && (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={300}
            ></Skeleton>
          )}

          {/* <Grid container justifyContent="flex-end" sx={{ width: "100%" }}>
            <Button onClick={() => OpenLogHistroy()}>
              <Grid container justifyContent="flex-start" pl={2}>
                <Typography sx={{ color: "black", fontSize: 14 }}>
                  過去の日記
                </Typography>
                <KeyboardDoubleArrowRightIcon
                  sx={{ color: "gray" }}
                ></KeyboardDoubleArrowRightIcon>
              </Grid>
            </Button>
          </Grid> */}
          <Box sx={{ height: 25 }}></Box>

          {/* <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"left"}
          >
            <Button
              sx={{ width: "70%", height: 40, backgroundColor: "#ffa94d" }}
              variant="contained"
              onClick={() => OpenLogHistroy()}
            >
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", color: "white" }}
              >
                すべての日記を見る
              </Typography>
            </Button>
          </Grid> */}
        </Grid>
      )}

      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"center"}
      >
        {dialy_deta[0] == "" && (
          <Grid>
            <Box sx={{ height: 10 }}></Box>

            <Typography noWrap={true} sx={{ mt: 1.1, fontSize: 16 }}>
              まだ日記を書いていません
            </Typography>
            <Box sx={{ height: 10 }}></Box>
          </Grid>
        )}
      </Grid>

      <Drawer
        anchor={"bottom"}
        open={state}
        onClose={() => toggleDrawer(false)}
      >
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          <EditComponent
            closeD={() => toggleDrawer(false)}
            slice_dialy_deta={dialy_deta}
            dialy_index={dialy_index}
            document_id={document_id}
            ids_deta={ids_deta}
            setReload={() => props.get_baseinfo()}
          />
        </Grid>
      </Drawer>

      {/* <Dialog
        onClose={() => ToggleAllDialyD(false)}
        open={alldialyD}
        fullScreen
      >
        <LogHistory
          closeD={() => ToggleAllDialyD(false)}
          dialy_deta={props.dialy_deta}
          lock={props.lock}
          toggleUnlockD={() => props.toggleUnlockD()}
          document_id={document_id}
          ids_deta={ids_deta}
        ></LogHistory>
      </Dialog> */}

      <Dialog
        onClose={() => setDeleteD(false)}
        open={deleteD}
        sx={{ width: "100%" }}
      >
        <Box sx={{ height: 40 }}></Box>
        <Grid p={2}>
          <Typography sx={{ fontSize: 14 }} mt={2} mr={2} ml={2}>
            削除してもよろしいですか？
          </Typography>
          <Box sx={{ height: 40 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#ffa94d",
                color: "white",
                width: "70%",
              }}
              onClick={() => onDelete()}
            >
              削除
            </Button>
            <Box sx={{ height: 20 }}></Box>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => setDeleteD(false)}
              style={{ width: "70%" }}
            >
              キャンセル
            </Button>
          </Grid>
          <Box sx={{ height: 40 }}></Box>
        </Grid>
      </Dialog>

      <Dialog
        onClose={() => setConfirmD(false)}
        open={confirmD}
        sx={{ width: "100%" }}
      >
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
          p={3}
        >
          <Box sx={{ height: 40 }}></Box>
          <Typography sx={{ fontSize: 14 }} mt={2} mr={2} ml={2}>
            削除しました！
          </Typography>
          <Box sx={{ height: 40 }}></Box>

          <Button
            variant="contained"
            style={{
              backgroundColor: "white",
              color: "black",
              width: "70%",
            }}
            onClick={() => setConfirmD(false)}
          >
            戻る
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>
    </Grid>
  );
}
