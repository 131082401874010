//日記を編集するコンポーネント

import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import yorokobi from "../../images/yorokobi.jpg";
import kitai from "../../images/kitai.jpg";
import fuman from "../../images/fuman.jpg";
import ikari from "../../images/ikari.jpg";
import kanasii from "../../images/kanasii.jpg";
import konwaku from "../../images/konwaku.jpg";
import kyofu from "../../images/kyofu.jpg";
import odoroki from "../../images/odoroki.jpg";
import { IconButton } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Avatar from "@mui/material/Avatar";
import { TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { db, app } from "../../firebase";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

export default function EditComponent(props) {
  const dialy_index = props.dialy_index;
  const document_id = props.document_id;
  const slice_dialy_deta = props.slice_dialy_deta;
  const ids_deta = props.ids_deta;

  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(
    parseInt(slice_dialy_deta[dialy_index]["feeling"])
  );
  const [activeOmikuji, setActiveOmikuji] = useState(
    parseInt(slice_dialy_deta[dialy_index]["omikuji"])
  );

  const [sendD, setSendD] = useState(false);
  const [confirmD, setConfirmD] = useState(false);
  const [value, setValue] = useState(slice_dialy_deta[dialy_index]["context"]);
  const [deleteD, setDeleteD] = useState(false);
  const [deleteCompleteD, setDeleteCompleteD] = useState(false);

  const [harauButton, setharauButton] = useState(false);
  const [kanaeruButton, setkanaeruButton] = useState(false);

  const feelings = [kitai, yorokobi, konwaku, kanasii];
  const omikuji = ["大吉", "吉", "中吉", "小吉", "半吉", "末吉", "凶", "大凶"];
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  var dayOfWeek = today.getDay();
  var dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek];
  const today_text =
    year + "年" + month + "月" + day + "日" + "(" + dayOfWeekStr + ")";

  // console.log("dialy_deta[dialy_index]: " + JSON.stringify(dialy_deta[dialy_index]["day"]));

  useEffect(() => {
    if (Object.keys(slice_dialy_deta[dialy_index]).length === 7) {
      setkanaeruButton(slice_dialy_deta[dialy_index]["kanaeru"]);
      setharauButton(slice_dialy_deta[dialy_index]["harau"]);
      // console.log("kanaeru: " + slice_dialy_deta[dialy_index]["kanaeru"]);
      // console.log("harau: " + slice_dialy_deta[dialy_index]["harau"]);
    }
  }, []);

  const selectHandler = (index, type) => {
    if (type === "feeling") {
      if (activeButton === index) {
        setActiveButton("");
      } else {
        setActiveButton(index);
      }
    } else if (type === "omikuji") {
      if (activeOmikuji === index) {
        setActiveOmikuji("");
      } else {
        setActiveOmikuji(index);
      }
    }
  };

  const toggleDeleteD = (open) => {
    setDeleteD(open);
  };

  const toggleDeleteCompleteD = (open) => {
    setDeleteCompleteD(open);
  };

  const sendDOpen = () => {
    if (value !== "") {
      setSendD(true);
    } else {
      return;
    }
  };
  const sendDClose = () => {
    setSendD(false);
  };
  const confirmDOpen = () => {
    setConfirmD(true);
  };
  const confirmDClose = () => {
    setConfirmD(false);
    props.closeD();
  };

  const onSubmit = async (action) => {
    setSendD(false);
    const timestamp = slice_dialy_deta[dialy_index]["timestamp"];
    const historyday = slice_dialy_deta[dialy_index]["day"];
    const deta = {
      day: historyday,
      context: value,
      feeling: activeButton,
      harau: harauButton,
      kanaeru: kanaeruButton,
      omikuji: activeOmikuji,
      timestamp,
    };
    const doc_id = ids_deta[dialy_index];
    // console.log("doc_id: " + doc_id);
    const docRef = doc(db, "account_list", props.document_id, "dialy", doc_id);
    try {
      await setDoc(docRef, deta).then(() => {
        props.setReload();
      });
    } catch (e) {
      // console.log("error" + e);
    }
    setConfirmD(true);
  };

  const onDelete = async () => {
    toggleDeleteD(false);
    const passRef = doc(
      db,
      "account_list",
      document_id,
      "dialy",
      ids_deta[dialy_index]
    );
    await deleteDoc(passRef).then(() => {
      props.setReload();
    });
    toggleDeleteCompleteD(true);
  };

  const escape = () => {
    toggleDeleteCompleteD(false);
    props.closeD();
    props.setReload();
  };

  const handleharau = (bool) => {
    setharauButton(bool);
    setkanaeruButton(false);
  };

  const handlekanaeru = (bool) => {
    setkanaeruButton(bool);
    setharauButton(false);
  };

  return (
    <Grid
      sx={{ width: "90%" }}
      textAlign="left"
      container
      alignItems="center"
      justify="center"
      direction="column"
    >
      <Grid>
        <Grid>
          <Box sx={{ height: 20 }}></Box>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={6}>
              <Typography
                sx={{ color: "black", fontSize: 16, fontWeight: "bold" }}
              >
                {slice_dialy_deta[dialy_index]["day"]}
              </Typography>
            </Grid>
            <Grid item xs={5.5} ml={0.5}>
              <Button
                variant="contained"
                sx={{ width: "100%", height: 40, backgroundColor: "white" }}
                onClick={() => toggleDeleteD(true)}
              >
                <Typography
                  sx={{ fontSize: 16, fontWeight: "bold", color: "#f46b6b" }}
                >
                  削除する
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ height: 20 }}></Box>
          <TextField
            multiline
            rows={7}
            autoFocus={false}
            sx={{ width: "100%" }}
            value={value}
            onChange={(event, newValue) => {
              setValue(event.target.value);
            }}
            inputProps={{ style: { fontSize: 20, fontWeight: "bold" } }}
          ></TextField>
          <Box sx={{ height: 20 }}></Box>
          <Typography sx={{ color: "black", fontSize: 15 }}>気持ち</Typography>
          <Box sx={{ height: 8 }}></Box>
          <Grid container rowSpacing={2} columnSpacing={1}>
            {feelings.map((img, index) => (
              <Grid item xs={3} key={index}>
                <Box
                  display="flex"
                  justifyContent="center"
                  justify="flex-start"
                  alignItems="center"
                >
                  <IconButton
                    onClick={() => selectHandler(`${index}`, "feeling")}
                  >
                    <Avatar
                      alt="feeling"
                      src={img}
                      sx={{ width: 50, height: 50 }}
                      style={{
                        border:
                          activeButton == `${index}`
                            ? "3px solid#f8a540"
                            : "1px solid lightgray",
                      }}
                    ></Avatar>
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ height: 20 }}></Box>
          <Typography sx={{ color: "black", fontSize: 15 }}>
            おみくじ
          </Typography>
          <Box sx={{ height: 8 }}></Box>
          <Grid container rowSpacing={0} columnSpacing={1}>
            {omikuji.map((text, index) => (
              <Grid item xs={3} key={index}>
                <Box
                  display="flex"
                  justifyContent="center"
                  justify="flex-start"
                  alignItems="center"
                >
                  <IconButton
                    onClick={() => selectHandler(`${index}`, "omikuji")}
                  >
                    <Avatar
                      alt="feeling"
                      style={{
                        border:
                          activeOmikuji == `${index}`
                            ? "3px solid#f8a540"
                            : "1px solid lightgray",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      sx={{ width: 50, height: 50 }}
                    >
                      {text}
                    </Avatar>
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ height: 20 }}></Box>
          <Typography sx={{ color: "black", fontSize: 15 }}>
            気持ちの種類
          </Typography>
          <Box sx={{ height: 15 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Grid container justifyContent={"flex-start"}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  style={{
                    width: "90%",
                    height: 40,
                    borderWidth: "3px",
                    borderColor: harauButton === true ? "#773dff" : "lightgray",
                  }}
                  onClick={() => handleharau(!harauButton)}
                >
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
                  >
                    祓いたいこと
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  style={{
                    width: "90%",
                    height: 40,
                    borderWidth: "3px",
                    borderColor:
                      kanaeruButton === true ? "#ffe53d" : "lightgray",
                    // "&.Mui-selected": { backgroundColor: "white" },
                  }}
                  onClick={() => handlekanaeru(!kanaeruButton)}
                >
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
                  >
                    叶えたいこと
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ height: 40 }}></Box>

          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              sx={{ width: "60%", height: 40, backgroundColor: "#ffa94d" }}
              onClick={() => sendDOpen()}
            >
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", color: "white" }}
              >
                更新する
              </Typography>
            </Button>
            <Box sx={{ height: 20 }}></Box>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => props.closeD()}
              style={{ width: "60%" }}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                もどる
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ height: 80 }}></Box>
      </Grid>
      <Dialog onClose={sendDClose} open={sendD} sx={{ width: "100%" }}>
        <Box sx={{ height: 40 }}></Box>
        <Grid p={2}>
          <Typography
            sx={{ fontSize: 16, fontWeight: "bold" }}
            mt={2}
            mr={2}
            ml={2}
          >
            更新してもよろしいですか？
          </Typography>
          <Box sx={{ height: 40 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#ffa94d",
                color: "white",
                width: "70%",
              }}
              onClick={() => onSubmit()}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                更新する
              </Typography>
            </Button>
            <Box sx={{ height: 20 }}></Box>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => sendDClose()}
              style={{ width: "70%" }}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                キャンセル
              </Typography>
            </Button>
          </Grid>
          <Box sx={{ height: 40 }}></Box>
        </Grid>
      </Dialog>

      <Dialog
        onClose={() => toggleDeleteD(false)}
        open={deleteD}
        sx={{ width: "100%" }}
      >
        <Box sx={{ height: 40 }}></Box>
        <Grid p={2}>
          <Typography
            sx={{ fontSize: 16, fontWeight: "bold" }}
            mt={2}
            mr={2}
            ml={2}
          >
            削除してもよろしいですか？
          </Typography>
          <Box sx={{ height: 40 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "red",
                color: "white",
                width: "70%",
              }}
              onClick={() => onDelete()}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                削除する
              </Typography>
            </Button>
            <Box sx={{ height: 20 }}></Box>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => toggleDeleteD(false)}
              style={{ width: "70%" }}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                キャンセル
              </Typography>
            </Button>
          </Grid>
          <Box sx={{ height: 40 }}></Box>
        </Grid>
      </Dialog>
      <Dialog onClose={confirmDClose} open={confirmD} sx={{ width: "100%" }}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
          p={3}
        >
          <Box sx={{ height: 40 }}></Box>
          <Typography
            sx={{ fontSize: 16, fontWeight: "bold" }}
            mt={2}
            mr={2}
            ml={2}
          >
            更新しました
          </Typography>
          <Box sx={{ height: 40 }}></Box>

          <Button
            variant="contained"
            style={{
              backgroundColor: "white",
              color: "black",
              width: "70%",
            }}
            onClick={() => confirmDClose()}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              もどる
            </Typography>
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>

      <Dialog
        onClose={() => toggleDeleteCompleteD(false)}
        open={deleteCompleteD}
        sx={{ width: "100%" }}
      >
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
          p={3}
        >
          <Box sx={{ height: 40 }}></Box>
          <Typography
            sx={{ fontSize: 16, fontWeight: "bold" }}
            mt={2}
            mr={2}
            ml={2}
          >
            削除しました
          </Typography>
          <Box sx={{ height: 40 }}></Box>

          <Button
            variant="contained"
            color="inherit"
            onClick={() => escape()}
            style={{ width: 240 }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              もどる
            </Typography>
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>
    </Grid>
  );
}
