import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import pic from "../../images/login.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate, Navigate, Link } from "react-router-dom";

const LoginComplete = (props) => {
  const navigate = useNavigate();

  const onSubmit = (action) => {
    if (action === "back") {
      props.handleBack();
    } else if (action === "next") {
      navigate("/");
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"center"}
    >
      <Box sx={{ height: 80 }}></Box>
      <img src={pic} alt="picture" style={{ width: "80%" }} />
      <Box sx={{ height: 10 }}></Box>

      <Card sx={{ width: "80%" }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
            ログインしました
          </Typography>
        </CardContent>
      </Card>

      <Box sx={{ height: 50 }}></Box>

      <Button
        sx={{ width: "60%", height: 55 }}
        onClick={() => onSubmit("next")}
        variant="contained"
        style={{ backgroundColor: "#ffa94d", color: "white" }}
      >
        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
          ホームへ
        </Typography>
      </Button>
      <Box sx={{ height: 50 }}></Box>
    </Grid>
  );
};

export default LoginComplete;
