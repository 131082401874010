import React, {
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
} from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { EasyApplyData } from "./EasyApplyPage.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { db, app } from "../firebase";
import { useNavigate, Navigate, Link } from "react-router-dom";
import ScrollTop from "../helper/ScrollTop.js";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import {
  collection,
  onSnapshot,
  addDoc,
  doc,
  query,
  getDocs,
  where,
  getDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { allowContextMenu } from "@fullcalendar/core";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import fuda from "../images/fuda.png";
import "../mplus-1p-bold-normal.js";

//EasyApply1[ [{アカウント情報},{申込内容}], [{アカウント情報2}, {申込内容2}]]

const Easyapply3 = (props) => {
  const { currentState, loading, setCurrentState } = useContext(EasyApplyData);
  const [num, setNum] = useState(1);
  const [time, setTime] = useState("");
  const [alldata, setAlldata] = useState([""]);
  const [open, setOpen] = React.useState(false);
  const [user_id, setUser_id] = useState("");
  //そのuserのDocument_id
  const [document_id, setDocumentId] = useState("");
  // PDF
  const pdfRef = useRef(new jsPDF("p", "pt", "a4", true));
  const [phone, setPhone] = useState("");
  const [email, setMail] = useState("");
  const [sumMonney, setSumMonney] = useState(0);
  const navigate = useNavigate();
  const [biko_text, setBikoText] = useState("");

  console.log("currentState =  " + JSON.stringify(currentState));

  useLayoutEffect(() => {
    get_info();
    console.log("currentState: " + JSON.stringify(currentState));
    const data = [];
    const people_num = currentState.EasyApply1.length;
    console.log(people_num);
    const apply1_data = currentState.EasyApply1;
    const booked_time = currentState.EasyApply2;
    //year, month, day, time
    setBikoText(currentState.biko);
    setNum(people_num);
    setTime(booked_time);

    const phone_value = phone;
    console.log("--->>>>>>" + phone_value);
    let booked_name = "";
    let booked_mail = "";

    //EasyApply1[ [{アカウント情報},{申込内容}], [{アカウント情報2}, {申込内容2}]]
    console.log("apply1_data[0]" + apply1_data[0]);
    let m = 0;

    for (let i = 0; i < people_num; i++) {
      console.log("i-> " + i);
      const name = apply1_data[i][0].name;
      const name_furi = apply1_data[i][0].name_furi;
      const postcode = apply1_data[i][0].postcode;
      const adress = apply1_data[i][0].adress;
      const adress_furi = apply1_data[i][0].adress_furi;
      const pray1 = apply1_data[i][1].pray1;
      const pray2 = apply1_data[i][1].pray2;
      const money = apply1_data[i][1].money;
      const birthday = apply1_data[i][0].birthday;
      const sex = apply1_data[i][0].sex;
      console.log("money: " + money);
      const int_money = stringToMoney(money);
      const new_money = m + int_money;
      console.log("new_money = " + new_money);
      m = new_money;

      if (i === 0) {
        data.push({
          name: name,
          phone: phone,
          email: email,
          birthday: birthday,
          sex: sex,
          name_furi: name_furi,
          postcode: postcode,
          adress: adress,
          adress_furi: adress_furi,
          pray1: pray1,
          pray2: pray2,
          money: money,
        });
      } else {
        data.push({
          name: name,
          birthday: birthday,
          sex: sex,
          name_furi: name_furi,
          postcode: postcode,
          adress: adress,
          adress_furi: adress_furi,
          pray1: pray1,
          pray2: pray2,
          money: money,
        });
      }
    }
    console.log("data->\n" + JSON.stringify(data));
    setAlldata(data);
    setSumMonney(m);
  }, []);

  const get_info = async () => {
    const auth = getAuth();
    const current_uid = auth.currentUser.uid;
    setUser_id(current_uid);
    const userRef = collection(db, "account_list");

    let docid = "";
    const q = query(userRef, where("uid", "==", current_uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setDocumentId(doc.id);
      console.log("doc id: " + doc.id);
      docid = doc.id;
    });

    //メアドと電話番号を取得
    const docRef = doc(db, "account_list", docid);
    const docSnap = await getDoc(docRef);
    const values = docSnap.data();
    console.log("email: " + values["email"]);
    console.log("phone: " + values["phone"]);
    setMail(values["email"]);
    setPhone(values["phone"]);
  };

  const onSubmit = (action) => {
    if (action === "back") {
      props.handleBack();
    } else if (action === "next") {
      //DBに申込内容追加
      AddFireStore();
      //もし、アカウントを登録していないユーザーがいたら、DBにアカウント追加
      //   AddAcount()
      //お客さまに送信
      SendConfirmMailToUser();

      //管理者に送信
      SendConfirmMailToAdmin();

      navigate("/thanks");
    }
  };

  //Firestore にデータ追加
  const AddFireStore = async () => {
    let dic = {};
    for (let i = 0; i < alldata.length; i++) {
      dic[`person.${i}`] = alldata[i];
    }

    //CurrentUserのDocument　idと一致するDBに追加したい
    console.log("document_id: " + document_id);
    const new_details = {
      content: dic,
      num: num,
      time: time,
      type: "apply1",
      timestamp: serverTimestamp(),
    };
    const docRef = doc(db, "account_list", document_id);
    const colRef = collection(docRef, "details");
    try {
      await addDoc(colRef, new_details);
    } catch (e) {
      console.log("error" + e);
    }

    //apply_listに追加
    // const ref = collection(db, "apply_list");
    // const documentRef = await addDoc(ref, {
    //   details: new_details,
    //   uid: user_id,
    //   cancel: false,
    //   confirm: false,
    // });
    // console.log("apply_list doc-> " + documentRef);
  };

  const Contenthandler = (first_word) => {
    console.log("phone: " + phone);
    let content = "";
    content =
      alldata[0]["name"] +
      "様\n\n" +
      first_word +
      time.year +
      "年 " +
      time.month +
      "月" +
      time.day +
      "日 " +
      time.time +
      "時〜\n\n電話番号: " +
      phone +
      "\nメールアドレス: " +
      email +
      "\n人数： " +
      num +
      "人" +
      "\n種類: 一般祈願" +
      "\n受取方法: " +
      time.receive +
      "\n備考: " +
      biko_text;
    for (let i = 0; i < alldata.length; i++) {
      content = content.concat(
        "\n\nーーーーーーーーーーーーーーーーーーー\nお名前：　" +
          alldata[i]["name"] +
          "\nフリガナ：　" +
          alldata[i]["name_furi"] +
          "\n願目１：　" +
          alldata[i]["pray1"] +
          "\n願目２：　" +
          alldata[i]["pray2"] +
          "\nおふだの大きさ：　" +
          alldata[i]["money"] +
          "\n性別：　" +
          alldata[i]["sex"] +
          "\n生まれた年：　" +
          alldata[i]["birthday"] +
          "\n住所：　" +
          alldata[i]["adress"] +
          "\nフリガナ：　" +
          alldata[i]["adress_furi"] +
          "\nーーーーーーーーーーーーーーーーーーー"
      );
    }
    content = content.concat(
      "\n\n大國魂神社　社務所\n電話番号: 0246-34-0092（9時〜16時）\n郵便番号: 970-0105\n住所: 福島県いわき市平菅波字宮前26"
    );
    return content;
  };

  const stringToMoney = (str) => {
    let int_money = 0;

    if (str == "三千円（普通）") {
      int_money = 3000;
    } else if (str == "三千円（小）") {
      int_money = 3000;
    } else if (str == "五千円（普通）") {
      int_money = 5000;
    } else if (str == "五千円（小）") {
      int_money = 5000;
    } else if (str == "壱万円（普通）") {
      int_money = 10000;
    } else if (str == "壱万円（小）") {
      int_money = 10000;
    }
    console.log("int_money: " + int_money);

    return int_money;
  };

  //userとadminにメールを送る
  const SendConfirmMailToUser = () => {
    let customer__word =
      "下記内容で受付しました。\n\n祭事都合により、ご予約頂いた日時が厳しい場合は神社から再度連絡致します。\n\n日時: ";
    const customerValues = {
      email: email,
      name: alldata[0]["name"],
      content: Contenthandler(customer__word),
    };
    // console.log("sendValues = " + JSON.stringify(customerValues));
    const functions = getFunctions(app, "asia-northeast1");
    let sendMail = httpsCallable(functions, "sendMailToUser_ookuni");
    sendMail(customerValues)
      .then(() => {
        console.log("Successed send mail.");
      })
      .catch((err) => {
        console.log("mail_error" + err);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const SendConfirmMailToAdmin = () => {
    let customer__word = "申込が届きました。\n\nご予約日時: ";

    //PDF作成
    console.log(jsPDF().getFontList());
    const baseimage = document.querySelector("#fuda");

    //申込人数が１人の場合

    if (num === 1) {
      var width = pdfRef.current.internal.pageSize.getWidth();
      var height = pdfRef.current.internal.pageSize.getHeight();

      pdfRef.current.addImage(
        baseimage,
        "PNG",
        30,
        70,
        0,
        height * 0.9,
        "",
        "FAST"
      );

      //メニュー1つ目
      pdfRef.current.setFont("mplus-1p-medium", "normal");
      pdfRef.current.setFontSize(45);
      pdfRef.current.text(stringSplit(alldata[0]["pray1"]), 200, 130);

      //メニュー2つ目
      pdfRef.current.text(stringSplit(alldata[0]["pray2"]), 55, 130);

      //住所
      pdfRef.current.setFontSize(25);
      pdfRef.current.text(stringSplit(alldata[0]["adress"]), 205, 430);

      //名前
      pdfRef.current.text(stringSplit(alldata[0]["name"]), 60, 430);

      pdfRef.current.setFontSize(20);

      //詳細
      const rep = "代表者:  " + alldata[0]["name"];
      const d =
        "希望日:  " +
        time.year +
        "年" +
        time.month +
        "月" +
        time.day +
        "日" +
        time.time +
        "時〜";
      const p = "電話番号: " + phone;
      const n = "申込人数: " + num;
      const sm = "合計:   " + sumMonney;
      const r = "受取方法: " + time.receive;
      const b = "備考:  " + biko_text;
      const split = "-----------";

      pdfRef.current.text(
        ["概要", split, rep, d, p, n, r, b, sm, split],
        300,
        130
      );

      const filename =
        alldata[0]["name"] + time.year + time.month + time.day + ".pdf";

      //  pdfRef.current.save("サンプル.pdf")
      const pdfOutput = pdfRef.current.output("datauristring");
      const customerValues = {
        email: "0092sma@gmail.com",
        name: "神社様",
        content: Contenthandler(customer__word),
        // attachment: [{ filename: filename, path: pdfOutput }],
      };

      const functions = getFunctions(app, "asia-northeast1");
      let sendMail = httpsCallable(functions, "sendMailToAdmin_ookuni");
      sendMail(customerValues)
        .then(() => {
          console.log("Successed send mail.");
        })
        .catch((err) => {
          console.log("mail_error" + err);
        })
        .finally(() => {
          console.log("finally");
        });
    } else {
      //申込人数が複数の場合
      console.log(num + "人");

      const pdfarr = [];

      for (let i = 0; i < num; i++) {
        const newpdf = new jsPDF("p", "pt", "a4", true);

        var width = pdfRef.current.internal.pageSize.getWidth();
        var height = pdfRef.current.internal.pageSize.getHeight();

        newpdf.addImage(baseimage, "PNG", 30, 70, 0, height * 0.9, "", "FAST");

        //メニュー1つ目
        newpdf.setFont("mplus-1p-medium", "normal");
        newpdf.setFontSize(45);
        newpdf.text(stringSplit(alldata[i]["pray1"]), 200, 130);

        //メニュー2つ目
        newpdf.text(stringSplit(alldata[i]["pray2"]), 55, 130);

        //住所
        newpdf.setFontSize(25);
        newpdf.text(stringSplit(alldata[i]["adress"]), 205, 430);

        //名前
        newpdf.text(stringSplit(alldata[i]["name"]), 60, 430);

        newpdf.setFontSize(20);

        //詳細
        const rep = "代表者:  " + alldata[0]["name"];
        const d =
          "希望日:  " +
          time.year +
          "年" +
          time.month +
          "月" +
          time.day +
          "日" +
          time.time +
          "時〜";
        const p = "電話番号: " + phone;
        const n = "申込人数: " + num;
        const sm = "合計:   " + sumMonney;
        const r = "受取方法: " + time.receive;
        const b = "備考:  " + biko_text;
        const split = "-----------";
        const personalname = "名前:   " + alldata[i]["name"];
        const personalname_f = "フリガナ: " + alldata[i]["name_furi"];
        const pray1 = "種類１:  " + alldata[i]["pray1"];
        const pray2 = "種類１:  " + alldata[i]["pray2"];
        const money = "おふだの大きさ: " + alldata[i]["money"];
        const sex = "性別:   " + alldata[i]["sex"];
        const birthday = "生まれた年: " + alldata[i]["birthday"];
        const address = "住所:  " + alldata[i]["adress"];
        const address_furi = "フリガナ: " + alldata[i]["adress_furi"];

        newpdf.text(
          [
            "概要",
            split,
            rep,
            d,
            p,
            n,
            r,
            b,
            sm,
            split,
            "詳細",
            split,
            personalname,
            personalname_f,
            pray1,
            pray2,
            sex,
            birthday,
            address,
            address_furi,
            money,
          ],
          300,
          130
        );

        const filename =
          alldata[i]["name"] +
          "様/" +
          time.year +
          "/" +
          time.month +
          "/" +
          time.day +
          ".pdf";

        const pdfOutput = newpdf.output("datauristring");

        pdfarr.push({ filename: filename, path: pdfOutput });
      }
      const customerValues = {
        email: "0092sma@gmail.com",
        name: "神社様",
        content: Contenthandler(customer__word),
        // attachment: pdfarr,
      };

      const functions = getFunctions(app, "asia-northeast1");
      let sendMail = httpsCallable(functions, "sendMailToAdmin_ookuni");
      sendMail(customerValues)
        .then(() => {
          console.log("Successed send mail.");
        })
        .catch((err) => {
          console.log("mail_error" + err);
        })
        .finally(() => {
          console.log("finally");
        });
    }
  };

  const stringSplit = (text) => {
    const arr = text.split("");
    return arr;
  };

  //確認Dialog
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    pdfRef.current.addFont("mplus-1p-medium-normal");
    pdfRef.current.setFont("mplus-1p-medium-normal"); // ↑で名付けた名前を設定する。
    let l = pdfRef.current.getFontList();
  }, []);

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"center"}
    >
      <img id="fuda" src={fuda} style={{ height: 300, display: "none" }} />
      <ScrollTop />
      <Box sx={{ height: 30 }}></Box>

      <Grid>
        <Grid container justify="flex-start" alignItems="flex-start">
          <CheckCircleOutlineIcon
            style={{ color: "#ffa94d", fontSize: 30 }}
          ></CheckCircleOutlineIcon>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }} ml={1}>
            ご確認ください
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ height: 30 }}></Box>

      <div id="pdf">
        {alldata.map((value, i) => (
          <Grid key={i}>
            <TableContainer component={Paper} sx={{ minWidth: 300 }}>
              <Table sx={{ minWidth: 100 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#fee6ceb4",
                        borderBottom: "1px solid gray",
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        項目
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: "#fee6ceb4",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        内容
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        お名前
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        {value.name}{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        フリガナ{" "}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        {value.name_furi}{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        郵便番号{" "}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        {value.postcode}{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        住所{" "}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        {value.adress}{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        フリガナ{" "}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        {value.adress_furi}{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        願目１{" "}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        {value.pray1}{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        願目2{" "}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        {value.pray2}{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        おふだ{" "}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                        {value.money}{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ height: 30 }}></Box>
          </Grid>
        ))}
        <Box sx={{ height: 30 }}></Box>

        <div>
          <TableContainer component={Paper} sx={{ minWidth: 300 }}>
            <Table sx={{ minWidth: 100 }} aria-label="table2">
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderRight: "1px solid gray",
                    }}
                  >
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      合計{" "}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      {sumMonney}円
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderRight: "1px solid gray",
                    }}
                  >
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      時間{" "}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      {time.year}年 {time.month}月{time.day}日 {time.time}時から
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderRight: "1px solid gray",
                    }}
                  >
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      受取方法{" "}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      {time.receive}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Box sx={{ height: 50 }}></Box>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"center"}
      >
        <Button
          sx={{ width: "60%", height: 40 }}
          variant="contained"
          onClick={handleOpen}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            確定する
          </Typography>
        </Button>
        <Box sx={{ height: 20 }}></Box>

        <Button
          variant="contained"
          sx={{
            width: "60%",
            height: 40,
            backgroundColor: "#ece9e9f2",
            color: "black",
          }}
          onClick={() => onSubmit("back")}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            もどる
          </Typography>
        </Button>
      </Grid>
      <Box sx={{ height: 120 }}></Box>
      <Dialog onClose={handleClose} open={open}>
        <Typography sx={{ fontSize: 16, fontWeight: "bold" }} p={3}>
          この内容で予約します、よろしいですか？
        </Typography>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          <Button
            variant="contained"
            sx={{ width: "60%", height: 40 }}
            onClick={() => onSubmit("next")}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              予約する
            </Typography>
          </Button>
          <Box sx={{ height: 20 }}></Box>

          <Button
            variant="contained"
            sx={{
              width: "60%",
              height: 40,
              backgroundColor: "#ece9e9f2",
              color: "black",
            }}
            onClick={handleClose}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              もどる
            </Typography>
          </Button>

          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default Easyapply3;
