import {
  React,
  useState,
  useLayoutEffect,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import g_oonusa from "../../images/g_oonusa.png";
import oonusa from "../../images/oonusa.png";
import torii from "../../images/torii.png";
import g_torii from "../../images/g_torii.png";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import PetsIcon from "@mui/icons-material/Pets";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";

export default function BottomMenu(props) {
  const [isiphone, set_isiphone] = useState(false);

  const CustomisedBottomNavigationAction = (props) => (
    <BottomNavigationAction sx={{ minWidth: "70px" }} {...props} />
  );

  const useStyles = makeStyles((theme) => ({
    bottomNavLabel: {
      fontWeight: "bold",
      fontSize: "20px",
    },
  }));
  const classes = useStyles();

  useLayoutEffect(() => {
    var userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.indexOf("iphone") != -1) {
      // console.log("iPhoneをお使いですね");
      set_isiphone(true);
    }
  }, []);

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        pb: isiphone ? 1.9 : 1.6,
        pt: 0.5,
        zIndex: 9,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={props.value}
        onChange={(event, newValue) => {
          // console.log("newValue: " + newValue);
          if (newValue === 0) {
          }
        }}
      >
        <CustomisedBottomNavigationAction
          icon={
            <HomeOutlinedIcon
              sx={{
                fontSize: 27,
                color: props.value === 0 ? "#ffa94d" : "#5D5D5D",
              }}
            />
          }
          component={Link}
          label={
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
              ホーム
            </Typography>
          }
          to="/"
        />
        {/* <CustomisedBottomNavigationAction
              icon={
                <HistoryEduOutlinedIcon
                  sx={{ fontSize: 27, color: "#5D5D5D" }}
                />
              }
              component={Link}
              label="まめ日記"
              to="/"
            /> */}
        <CustomisedBottomNavigationAction
          icon={
            <VolunteerActivismOutlinedIcon
              sx={{
                fontSize: 27,
                color: props.value === 1 ? "#ffa94d" : "#5D5D5D",
              }}
            />
          }
          label={
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
              ご祈祷
            </Typography>
          }
          component={Link}
          to="/kigan"
        />
        {/* <CustomisedBottomNavigationAction
          icon={
            <PetsIcon
              sx={{
                fontSize: 27,
                color: props.value === 2 ? "#ffa94d" : "#5D5D5D",
              }}
            />
          }
          component={Link}
          label={
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
              育成
            </Typography>
          }
          to="/room"
        /> */}
        {/* <CustomisedBottomNavigationAction
          icon={
            <LightbulbOutlinedIcon
              sx={{
                fontSize: 27,
                color: props.value === 2 ? "#ffa94d" : "#5D5D5D",
              }}
            />
          }
          component={Link}
          label={
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
              クイズ
            </Typography>
          }
          to="/quiz"
        />{" "} */}
        {/* <CustomisedBottomNavigationAction
          icon={
            <AutoStoriesOutlinedIcon
              sx={{
                fontSize: 27,
                color: props.value === 3 ? "#ffa94d" : "#5D5D5D",
              }}
            />
          }
          component={Link}
          label={
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
              御朱印
            </Typography>
          }
          to="/kamidana"
        /> */}
        {/* <BottomNavigationAction
          icon={
            <GroupIcon
              sx={{
                fontSize: 27,
                color: props.value === 3 ? "#ffa94d" : "#5D5D5D",
              }}
            />
          }
          component={Link}
          label={
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
              設定
            </Typography>
          }
          to="/setting"
        /> */}
      </BottomNavigation>
    </Paper>
  );
}
