import React, {
  useState,
  useContext,
  useLayoutEffect,
  useEffect,
  useRef,
} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, TextField } from "@mui/material";
import tukinamigohei from "../../images/tukinamigohei.jpg";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import looking_fox from "../../images/looking_fox.png";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import torii from "../../images/torii.png";
import oonusa from "../../images/oonusa.png";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { useForm, Controller } from "react-hook-form";
import BottomMenu from "../../ Component/Sub/BottomMenu.js";
import { Player } from "@lottiefiles/react-lottie-player";
import complete from "../../movies/complete.json";
import { motion } from "framer-motion";
import ex from "../../images/ex.png";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

export default function Walkthrough(props) {
  const navigate = useNavigate();

  const [state, setState] = useState(false);
  const { register, handleSubmit, getValues, setValue } = useForm();

  const [index, setIndex] = useState(0); //インデックス
  const [nickname, setNickname] = useState(""); //ニックネーム

  const [wrong_lottie, set_wrong_lottie] = useState(null);
  const player3 = useRef(null);
  const [complete_lottie, set_complete_lottie] = useState(null);
  const [complete_loading, set_complete_Loading] = useState(false);
  const [showEx, set_showEx] = useState(false);

  useEffect(() => {
    if (complete_lottie) {
      complete_lottie.play();
    }
  }, [complete_lottie]);

  const toggleDrawer = () => {
    setState(!state);
  };

  const toggleIndex = (n) => {
    const input_data = getValues();
    console.log("Input data: " + JSON.stringify(input_data));
    if (index === 0 && input_data.nickname == "") {
      console.log("入力されていません");
      return;
    }
    if (n === 1) {
      const input_data = getValues();
      console.log("Input data: " + JSON.stringify(input_data));
      setNickname(input_data.nickname);
    }
    if (n === 5) {
      set_complete_Loading(true);
      set_showEx(true);
      setTimeout(() => {
        set_showEx(false);
      }, 3000);
      AddNickNameDB();
      return;
    }
    setIndex(n);
  };

  const AddNickNameDB = async () => {
    try {
      console.log(props.document_id);
      const docRef = doc(db, "account_list", props.document_id);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      if (typeof data["values"] !== "undefined") {
        let before_dic = data["values"][0];
        console.log("before_dic: " + JSON.stringify(before_dic));
        before_dic["tutorial_complete"] = true;
        const after_dic = before_dic;
        console.log("after_dic: " + JSON.stringify(after_dic));
        const deta_dic = { values: [after_dic] };
        await updateDoc(docRef, deta_dic);
        props.CloseDialog();
      } else {
        console.log("AddNickNameDBで values が undefined です");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const Content = () => {
    if (index === 0) {
      return (
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            container
            textAlign={"center"}
            alignItems="center"
            justifyContent="center"
          >
            <Grid container alignItems="center" justifyContent="center">
              <img src={looking_fox} height="120" />
            </Grid>

            <Card sx={{ width: "90%", mb: 3 }}>
              <CardContent>
                <Typography
                  sx={{ color: "black", fontWeight: "bold", fontSize: 18 }}
                >
                  登録してくれてありがとう！
                </Typography>
                <Typography
                  sx={{ color: "black", fontWeight: "bold", fontSize: 18 }}
                >
                  君のことなんて呼べばいい？
                </Typography>

                <form onSubmit={handleSubmit(AddNickNameDB)}>
                  <TextField
                    // autoFocus
                    className={classes.root}
                    sx={{ mt: 3 }}
                    placeholder="ニックネーム"
                    type="text"
                    {...register("nickname", { required: true })}
                  />
                </form>
              </CardContent>
            </Card>
          </Grid>

          <Button
            onClick={() => toggleIndex(1)}
            sx={{
              backgroundColor: "#ffa94d",
              color: "white",
              width: "60%",
              height: 55,
              mb: 2,
            }}
            variant="contained"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
              つぎへ
            </Typography>
          </Button>
          <Button
            onClick={() => props.CloseDialog()}
            sx={{
              backgroundColor: "#ece9e9f2",
              width: "60%",
              height: 55,
            }}
            variant="contained"
            my={2}
          >
            <Typography
              sx={{ color: "black", fontWeight: "bold", fontSize: 18 }}
            >
              もどる
            </Typography>
          </Button>
        </Grid>
      );
    } else if (index === 1) {
      return (
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            container
            textAlign={"center"}
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 4 }}
          >
            <Grid container alignItems="center" justifyContent="center">
              <img src={looking_fox} height="120" />
            </Grid>

            <Card sx={{ width: "90%", mb: 3 }}>
              <CardContent>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 0.5,
                  }}
                >
                  {nickname} さんでいい？
                </Typography>
                <Typography
                  sx={{ color: "black", fontWeight: "bold", fontSize: 18 }}
                >
                  ※あとで変更ができます
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Button
            onClick={() => toggleIndex(2)}
            sx={{
              backgroundColor: "#ffa94d",
              color: "white",
              width: "60%",
              height: 55,
              mb: 2,
            }}
            variant="contained"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
              いいよ
            </Typography>
          </Button>
          <Button
            onClick={() => toggleIndex(0)}
            sx={{
              backgroundColor: "#ece9e9f2",
              width: "60%",
              height: 55,
            }}
            variant="contained"
            my={2}
          >
            <Typography
              sx={{ color: "black", fontWeight: "bold", fontSize: 18 }}
            >
              もどる
            </Typography>
          </Button>
        </Grid>
      );
    } else if (index === 2) {
      return (
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            container
            direction={"row"}
            textAlign={"left"}
            alignItems="center"
            justifyContent="center"
          >
            <Card sx={{ width: "90%", mb: 3 }}>
              <CardContent>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 2,
                  }}
                >
                  {nickname}さんよろしく！
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 2,
                  }}
                >
                  まず、大國魂(おおくにたま)神社には「4人の神さま(御祭神)」がいらっしゃるよ
                </Typography>
                <Typography
                  sx={{
                    color: "#ffa94d",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 0.5,
                  }}
                >
                  大己貴命(おおなむちのみこと)
                </Typography>
                <Typography
                  sx={{
                    color: "#ffa94d",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 0.5,
                  }}
                >
                  事代主命(ことしろぬしのみこと)
                </Typography>
                <Typography
                  sx={{
                    color: "#ffa94d",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 0.5,
                  }}
                >
                  大己貴命(おおなむちのみこと)
                </Typography>
                <Typography
                  sx={{
                    color: "#ffa94d",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 2,
                  }}
                >
                  須世理比売命(すせりひめのみこと)
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 0.5,
                  }}
                >
                  そして御祭神から伝えられたことは...。
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Button
            onClick={() => toggleIndex(3)}
            sx={{
              backgroundColor: "#ffa94d",
              color: "white",
              width: "60%",
              height: 55,
              mb: 2,
            }}
            variant="contained"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
              つぎへ
            </Typography>
          </Button>
          <Button
            onClick={() => toggleIndex(0)}
            sx={{
              backgroundColor: "#ece9e9f2",
              color: "black",
              width: "60%",
              height: 55,
              mb: 2,
            }}
            variant="contained"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
              もどる
            </Typography>
          </Button>
        </Grid>
      );
    } else if (index === 3) {
      return (
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            container
            direction={"row"}
            textAlign={"left"}
            alignItems="center"
            justifyContent="center"
          >
            <Card sx={{ width: "90%", mb: 3 }}>
              <CardContent>
                <Typography
                  sx={{
                    color: "#ffa94d",
                    fontWeight: "bold",
                    fontSize: 20,
                    mb: 2,
                  }}
                >
                  「参拝」や「おつかい」をこなして勾玉(まがたま)を集めよ！
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 2,
                  }}
                >
                  僕ひとりでは難しくて、{nickname}さんが頼りなんだ。
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 0.5,
                  }}
                >
                  〜 勾玉の集め方 〜
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 0.5,
                  }}
                >
                  ・ご祈願をする✖️20コ
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 0.5,
                  }}
                >
                  ・参拝を記録する✖️10コ
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 0.5,
                  }}
                >
                  ・おつかいをする✖️3コ
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 2,
                  }}
                >
                  ・御朱印を頂く✖️3コ
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 0.5,
                  }}
                >
                  〜 勾玉の使い方 〜
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 18,
                    mb: 0.5,
                  }}
                >
                  集めた勾玉で、コンの衣装や小物を取得できるよ。
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Button
            onClick={() => toggleIndex(4)}
            sx={{
              backgroundColor: "#ffa94d",
              color: "white",
              width: "60%",
              height: 55,
              mb: 2,
            }}
            variant="contained"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
              つぎへ
            </Typography>
          </Button>
          <Button
            onClick={() => toggleIndex(2)}
            sx={{
              backgroundColor: "#ece9e9f2",
              color: "black",
              width: "60%",
              height: 55,
              mb: 2,
            }}
            variant="contained"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
              もどる
            </Typography>
          </Button>
        </Grid>
      );
    } else if (index === 4) {
      return (
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            container
            textAlign={"left"}
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 4 }}
          >
            <Grid container alignItems="center" justifyContent="center">
              <img src={looking_fox} height="120" />
            </Grid>

            <Card sx={{ width: "90%", mb: 3 }}>
              <CardContent>
                <Typography
                  sx={{ color: "black", fontWeight: "bold", fontSize: 18 }}
                >
                  {nickname} さん、一緒にたくさん勾玉を集めようね！
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Button
            onClick={() => toggleIndex(5)}
            sx={{
              backgroundColor: "#ffa94d",
              color: "white",
              width: "60%",
              height: 55,
              mb: 2,
            }}
            variant="contained"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
              うん！
            </Typography>
          </Button>
          <Button
            onClick={() => toggleIndex(3)}
            sx={{
              backgroundColor: "#ece9e9f2",
              width: "60%",
              height: 55,
            }}
            variant="contained"
            my={2}
          >
            <Typography
              sx={{ color: "black", fontWeight: "bold", fontSize: 18 }}
            >
              もどる
            </Typography>
          </Button>
        </Grid>
      );
    }
  };

  const goSignup = () => {
    navigate("/signup");
  };

  useEffect(() => {}, [index]);

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiOutlinedInput-input": {
        border: 0,
        borderRadius: 3,
        fontSize: 18,
        fontWeight: "bold",
      },
    },
    complete: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    extext: {
      position: "fixed",
      top: "20%",
    },
  }));

  const classes = useStyles();

  return (
    <Grid
      container
      textAlign={"left"}
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100%" }}
    >
      {complete_loading && (
        <Player
          lottieRef={(data) => {
            set_complete_lottie(data);
          }}
          onEvent={(event) => {
            if (event === "complete") {
              complete_lottie.stop();
              set_complete_Loading(false);
            }
          }}
          ref={player3}
          src={complete}
          keepLastFrame={true}
          loop={3}
          autoPlay={false}
          style={{ height: 390, width: 390 }}
          className={classes.complete}
          speed={0.9}
        />
      )}

      {showEx && (
        <Grid className={classes.extext}>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            animate={{ y: "-50px", x: "-40px" }}
          >
            <Grid container alignItems={"center"} justifyContent={"center"}>
              <img src={ex} height={50} />
              <Typography sx={{ fontSize: 44, fontWeight: "bold", ml: 1 }}>
                +28
              </Typography>
            </Grid>
          </motion.div>
        </Grid>
      )}

      <Content />
    </Grid>
  );
}
