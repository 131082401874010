import { React, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createContext } from "react";
import Thanks from "../Thanks.js";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Yakubarai_1 from "./Yakubarai_1.js";
import Yakubarai_2 from "./Yakubarai_2.js";
import Yakubarai_3 from "./Yakubarai_3.js";
import ScrollTop from "../../helper/ScrollTop.js";

import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import torii from "../../images/torii.png";
import oonusa from "../../images/oonusa.png";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { useNavigate, Navigate, Link } from "react-router-dom";

import BottomMenu from "../Sub/BottomMenu.js";

export const OtherApplyData = createContext();

function getSteps() {
  return ["選択", "日時", "確認"];
}

const BaseOfYakubarai = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [currentState, setCurrentState] = useState();

  const navigate = useNavigate();
  const value = {
    currentState,
    setCurrentState,
  };

  const steps = getSteps();
  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiStepIcon-active": { color: "#ffa94d" },
      "& .MuiStepIcon-completed": { color: "#ffa94d" },
    },
  }));

  const c = useStyles();

  const handleNext = () => {
    const newPage = activeStep + 1;
    setActiveStep(newPage);
  };
  const handleBack = () => {
    const newPage = activeStep - 1;
    setActiveStep(newPage);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const goHome = () => {
    navigate("/");
    console.log("goHome was called");
  };

  const goThanks = () => {
    navigate("/thanks");
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Yakubarai_1
            handleNext={() => handleNext()}
            goHome={() => goHome()}
            accounts={props.accounts}
          ></Yakubarai_1>
        );

      case 1:
        return (
          <Yakubarai_2
            handleNext={handleNext}
            handleBack={handleBack}
          ></Yakubarai_2>
        );
      case 2:
        return (
          <Yakubarai_3
            handleNext={handleNext}
            handleBack={handleBack}
          ></Yakubarai_3>
        );
      // case 3:
      //     return <Apply4 handleNext={handleNext} handleBack={handleBack}></Apply4>;
      //  case 4:
      //     return <Apply5  handleNext={goThanks} handleBack={handleBack}></Apply5>;
      default:
        return "Unknown stepIndex";
    }
  }

  const CustomisedBottomNavigationAction = (props) => (
    <BottomNavigationAction sx={{ minWidth: "70px" }} {...props} />
  );
  return (
    <Grid>
      {activeStep === steps.length ? (
        <div>
          <Thanks handleBack={handleBack} goHome={goHome}></Thanks>
        </div>
      ) : (
        <div>
          <ScrollTop />
          <Box sx={{ height: 20 }}></Box>
          <Grid item={true} mb={2} />
          <Grid item={true} lg={8} sm={8}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className={c.root}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>
                    <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Box sx={{ height: 10 }}></Box>
          <OtherApplyData.Provider value={value}>
            {getStepContent(activeStep)}
          </OtherApplyData.Provider>
        </div>
      )}
      <BottomMenu value={8} />
    </Grid>
  );
};

export default BaseOfYakubarai;
