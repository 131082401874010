import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import Avatar from "@mui/material/Avatar";
import yorokobi from "../../images/yorokobi.jpg";
import kitai from "../../images/kitai.jpg";
import kanasii from "../../images/kanasii.jpg";
import konwaku from "../../images/konwaku.jpg";
import Drawer from "@mui/material/Drawer";
import EditComponent from "../ Component/EditComponent";
import Dialog from "@mui/material/Dialog";
import ScrollTop from "../../helper/ScrollTop.js";
import { getAuth } from "firebase/auth";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CardActionArea from "@mui/material/CardActionArea";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import BottomMenu from "../../ Component/Sub/BottomMenu";

import ex from "../../images/ex.png";
import {
  collection,
  deleteDoc,
  doc,
  query,
  getDocs,
  orderBy,
  where,
} from "firebase/firestore";
import { db, app } from "../../firebase";
import IconButton from "@mui/material/IconButton";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

export default function LogHistory(props) {
  const navigate = useNavigate();
  // console.log("LogHistory: " + JSON.stringify(dialy_deta));

  const [dialy_index, setDialy_index] = useState(0);

  const [day, setDay] = useState("");
  const [context, setContext] = useState("");
  const [feeling, setFeeling] = useState([""]);
  const [omikuji, setOmikuji] = useState([""]);

  const [deleteD, setDeleteD] = useState(false);
  const [confirmD, setConfirmD] = useState(false);

  const [index, setIndex] = useState("");

  const [dialy_deta, setDialy_deta] = useState("");
  const [ids_deta, setIds_deta] = useState("");
  const [document_id, setDocumentId] = useState("");

  const [state, setState] = useState(false);

  useLayoutEffect(() => {
    setDialy_deta(props.dialy_deta);
  }, []);

  const toggleDrawer = (open) => {
    setState(open);
  };

  const Delete = (index) => {
    setDeleteD(true);
    setIndex(index);
  };

  const onDelete = async () => {
    const docid = ids_deta[index];
    const targetdocRef = doc(db, "account_list", document_id, "dialy", docid);

    await deleteDoc(targetdocRef);

    // console.log("削除しました");
    setDeleteD(false);
    get_baseinfo();
  };

  const goDialyHome = () => {
    navigate("/dialy");
  };

  const openDrawer = (index) => {
    toggleDrawer(true);
    setDialy_index(index);
  };

  const get_baseinfo = async () => {
    try {
      const auth = getAuth();
      const current_uid = auth.currentUser.uid;
      const userRef = collection(db, "account_list");

      let docid = "";
      const q = query(userRef, where("uid", "==", current_uid));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setDocumentId(doc.id);
        // console.log("doc id: " + doc.id);
        docid = doc.id;
      });
      get_dialyinfo(userRef, docid);
    } catch (e) {
      // console.log("error: " + e.message);
    }
  };

  //日記を取得
  const get_dialyinfo = async (userRef, docid) => {
    try {
      let index = 0;
      let dialy_values = [""];
      let dialy_ids = [""];
      const accountsRef = collection(db, "account_list", docid, "dialy");

      const dialy_query = query(
        accountsRef,
        orderBy("timestamp", "asc")
        // limit(2)
      );
      const accountsSnaps = await getDocs(dialy_query);
      accountsSnaps.forEach((d) => {
        dialy_values[index] = d.data();
        dialy_ids[index] = d.id;

        index += 1;
      });
      setDialy_deta(dialy_values);
      setIds_deta(dialy_ids);

      if (dialy_values !== "") {
        let day_arr = [];
        for (let i = 0; i < dialy_values.length; i++) {
          if (i === 0) {
            day_arr = [dialy_values[i]["day"]];
          } else {
            day_arr.push(dialy_values[i]["day"]);
          }
        }
        let context_arr = [];
        let feeling_arr = [];
        let omikuji_arr = [];

        for (let i = 0; i < dialy_values.length; i++) {
          if (i === 0) {
            context_arr = [dialy_values[i]["context"]];

            switch (parseInt([dialy_values[i]["feeling"]])) {
              case 0:
                feeling_arr = [kitai];
                break;
              case 1:
                feeling_arr = [yorokobi];
                break;
              case 2:
                feeling_arr = [konwaku];
                break;
              case 3:
                feeling_arr = [kanasii];
                break;
              default:
                feeling_arr = [""];
            }

            switch (parseInt([dialy_values[i]["omikuji_arr"]])) {
              case 0:
                omikuji_arr = [0];
                break;
              case 1:
                omikuji_arr = [1];
                break;
              case 2:
                omikuji_arr = [2];
                break;
              case 3:
                omikuji_arr = [3];
                break;
              default:
                omikuji_arr = [""];
            }
          } else {
            context_arr.push(dialy_values[i]["context"]);

            switch (parseInt([dialy_values[i]["feeling"]])) {
              case 0:
                feeling_arr.push(kitai);
                break;
              case 1:
                feeling_arr.push(yorokobi);

                break;
              case 2:
                feeling_arr.push(konwaku);

                break;
              case 3:
                feeling_arr.push(kanasii);

                break;
              default:
                feeling_arr.push("");
            }

            switch (parseInt([dialy_values[i]["omikuji_arr"]])) {
              case 0:
                omikuji_arr.push(0);
                break;
              case 1:
                omikuji_arr.push(1);
                break;
              case 2:
                omikuji_arr.push(2);
                break;
              case 3:
                omikuji_arr.push(3);
                break;
              default:
            }
          }
        }
        setDay(day_arr);
        setContext(context_arr);
        setFeeling(feeling_arr);
        setOmikuji(omikuji_arr);
      }
    } catch (e) {
      // console.log("error: " + e.message);
    }
  };

  const get_feelings = () => {
    let feeling_arr = [];
    for (let i = 0; i < dialy_deta.length; i++) {
      if (i === 0) {
        switch (parseInt([dialy_deta[i]["feeling"]])) {
          case 0:
            feeling_arr = [kitai];
            break;
          case 1:
            feeling_arr = [yorokobi];
            break;
          case 2:
            feeling_arr = [konwaku];
            break;
          case 3:
            feeling_arr = [kanasii];
            break;
          default:
            feeling_arr = [""];
          // console.log("default was called");
        }
      } else {
        switch (parseInt([dialy_deta[i]["feeling"]])) {
          case 0:
            feeling_arr.push(kitai);
            break;
          case 1:
            feeling_arr.push(yorokobi);

            break;
          case 2:
            feeling_arr.push(konwaku);

            break;
          case 3:
            feeling_arr.push(kanasii);

            break;
          default:
            feeling_arr.push("");
          // console.log("default was called");
        }
      }
    }
    return feeling_arr;
  };

  const feelings = get_feelings();

  const handleOpen = (index) => {
    openDrawer(index);
  };

  return (
    <Grid container>
      <ScrollTop />
      <Grid item xs={12}>
        <Box sx={{ height: 20 }}></Box>
        <Grid container justifyContent="flex-start" sx={{ width: "95%" }}>
          <Button onClick={() => props.closeD()}>
            <Grid container justifyContent="flex-start">
              <KeyboardDoubleArrowLeftIcon
                sx={{ color: "gray" }}
              ></KeyboardDoubleArrowLeftIcon>
              <Typography sx={{ color: "black", fontSize: 14 }}>
                戻る
              </Typography>
            </Grid>
          </Button>
        </Grid>
        <Box sx={{ height: 20 }}></Box>

        <Grid container justify="flex-start" alignItems="center">
          <Typography sx={{ ml: 2, fontSize: 18, fontWeight: "bold" }}>
            過去の日記
          </Typography>
        </Grid>

        <Box sx={{ height: 20 }}></Box>

        {dialy_deta !== "" &&
          dialy_deta.map((deta, i) => (
            <Grid
              key={i}
              container
              alignItems="center"
              justify="center"
              direction="column"
              textAlign={"left"}
            >
              <Card sx={{ width: "90%" }}>
                <CardActionArea onClick={() => handleOpen(i)}>
                  <Grid>
                    <Grid sx={{ width: "100%" }} p={3}>
                      <Grid container justify="flex-start" alignItems="center">
                        <Grid item xs={9}>
                          <Typography sx={{ fontSize: 14 }} variant="body2">
                            {dialy_deta[i]["day"]}
                          </Typography>
                          <Box sx={{ width: 200 }} mt={1.5}>
                            <Typography
                              noWrap={true}
                              sx={{ mt: 1.1, fontSize: 15 }}
                              variant="body2"
                            >
                              {dialy_deta[i]["context"]}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={0.5}>
                          <Box sx={{ height: 20 }}></Box>
                        </Grid>
                        <Grid item xs={2.5}>
                          {feelings[i] !== "" && (
                            <Avatar
                              src={feelings[i]}
                              style={{
                                border: "0.1px solid lightgray",
                              }}
                              sx={{ width: 50, height: 50 }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
              <Box sx={{ height: 10 }}></Box>
            </Grid>
          ))}

        <Box sx={{ height: 200 }}></Box>
      </Grid>

      <Dialog fullScreen open={state} onClose={() => toggleDrawer(false)}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          <EditComponent
            closeD={() => toggleDrawer(false)}
            slice_dialy_deta={props.dialy_deta}
            dialy_index={dialy_index}
            document_id={props.document_id}
            ids_deta={props.ids_deta}
            setReload={() => get_baseinfo()}
          />
        </Grid>
      </Dialog>

      <Dialog
        onClose={() => setDeleteD(false)}
        open={deleteD}
        sx={{ width: "100%" }}
      >
        <Box sx={{ height: 40 }}></Box>
        <Grid p={2}>
          <Typography sx={{ fontSize: 14 }} mt={2} mr={2} ml={2}>
            削除してもよろしいですか？
          </Typography>
          <Box sx={{ height: 40 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#ffa94d",
                color: "white",
                width: "70%",
              }}
              onClick={() => onDelete()}
            >
              削除
            </Button>
            <Box sx={{ height: 20 }}></Box>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => setDeleteD(false)}
              style={{ width: "70%" }}
            >
              キャンセル
            </Button>
          </Grid>
          <Box sx={{ height: 40 }}></Box>
        </Grid>
      </Dialog>

      <Dialog
        onClose={() => setConfirmD(false)}
        open={confirmD}
        sx={{ width: "100%" }}
      >
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
          p={3}
        >
          <Box sx={{ height: 40 }}></Box>
          <Typography sx={{ fontSize: 14 }} mt={2} mr={2} ml={2}>
            削除しました！
          </Typography>
          <Box sx={{ height: 40 }}></Box>

          <Button
            variant="contained"
            style={{
              backgroundColor: "white",
              color: "black",
              width: "70%",
            }}
            onClick={() => setConfirmD(false)}
          >
            戻る
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>

      <BottomMenu value={8} />
    </Grid>
  );
}
