//日記を記録するコンポーネント

import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import yorokobi from "../../images/yorokobi.jpg";
import kitai from "../../images/kitai.jpg";
import fuman from "../../images/fuman.jpg";
import ikari from "../../images/ikari.jpg";
import kanasii from "../../images/kanasii.jpg";
import konwaku from "../../images/konwaku.jpg";
import kyofu from "../../images/kyofu.jpg";
import odoroki from "../../images/odoroki.jpg";
import { IconButton } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Avatar from "@mui/material/Avatar";
import { TextField } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { db, app } from "../../firebase";
import { collection, addDoc, doc, serverTimestamp } from "firebase/firestore";
import Contact from "../../ Component/Sub/Contact";
import Divider from "@mui/material/Divider";

export default function InputDialy(props) {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("");
  const [activeOmikuji, setActiveOmikuji] = useState("");
  const [sendD, setSendD] = useState(false);
  const [confirmD, setConfirmD] = useState(false);
  const [alertD, setAlertD] = useState(false);
  const [already_recordD, Setalready_recordD] = useState(false);
  const [harauButton, setharauButton] = useState(false);
  const [kanaeruButton, setkanaeruButton] = useState(false);

  const [value, setValue] = useState("");

  const alert = props.alert;

  useEffect(() => {}, [props.record_or_not]);

  const feelings = [kitai, yorokobi, konwaku, kanasii];
  const omikuji = ["大吉", "吉", "中吉", "小吉", "半吉", "末吉", "凶", "大凶"];
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  var dayOfWeek = today.getDay();
  var dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek];
  const today_text =
    year + "年" + month + "月" + day + "日" + "(" + dayOfWeekStr + ")";

  const selectHandler = (index, type) => {
    if (type === "feeling") {
      if (activeButton === index) {
        setActiveButton("");
      } else {
        setActiveButton(index);
      }
    } else if (type === "omikuji") {
      if (activeOmikuji === index) {
        setActiveOmikuji("");
      } else {
        setActiveOmikuji(index);
      }
    }
  };

  const sendDOpen = () => {
    if (value !== "") {
      setSendD(true);
    } else {
      return;
    }
  };
  const sendDClose = () => {
    setSendD(false);
  };
  const confirmDOpen = () => {
    setConfirmD(true);
  };
  const confirmDClose = () => {
    setConfirmD(false);
    navigate("/dialy");
  };

  const alertDOpen = () => {
    setAlertD(true);
  };
  const alertDClose = () => {
    setAlertD(false);
  };
  const alreadyDOpen = () => {
    Setalready_recordD(true);
  };
  const alreadyDClose = () => {
    Setalready_recordD(false);
  };

  const onSubmit = async (action) => {
    if (!props.record_or_not) {
      //本日は既に記録していますDialogを表示
      sendDClose();
      alreadyDOpen();
    } else {
      sendDClose();

      if (!alert) {
        const deta = {
          day: today_text,
          context: value,
          feeling: parseInt(activeButton),
          harau: harauButton,
          kanaeru: kanaeruButton,
          omikuji: parseInt(activeOmikuji),
          timestamp: serverTimestamp(),
        };
        const docRef = doc(db, "account_list", props.document_id);
        const colRef = collection(docRef, "dialy");
        try {
          await addDoc(colRef, deta);
        } catch (e) {
          console.log("error" + e);
        }
        confirmDOpen();
        props.get_baseinfo();
      } else {
        alertDOpen();
      }
    }
  };

  const escape = () => {
    confirmDClose();
    sendDClose();
    props.CloseD();
  };

  const goSignup = () => {
    navigate("/signup");
  };

  const handleharau = (bool) => {
    setharauButton(bool);
    setkanaeruButton(false);
  };

  const handlekanaeru = (bool) => {
    setkanaeruButton(bool);
    setharauButton(false);
  };

  const placeholder_text = `・今感じていること\n・叶えたいこと\n・食事\n・秘密にしたい事\n・感謝の気持ち\n\n小さな気持ちを見つけてみましょう`;

  return (
    <Grid sx={{ width: "90%" }} textAlign="left">
      <Box sx={{ height: 40 }}></Box>

      <Grid>
        <Grid>
          <Typography sx={{ color: "black", fontSize: 18, fontWeight: "bold" }}>
            {today_text}
          </Typography>
          <Box sx={{ height: 20 }}></Box>

          <Typography sx={{ color: "black", fontSize: 15 }}>感情</Typography>

          <Box sx={{ height: 5 }}></Box>
          <Grid container rowSpacing={2} columnSpacing={1}>
            {feelings.map((img, index) => (
              <Grid item xs={3} key={index}>
                <Box
                  display="flex"
                  justifyContent="center"
                  justify="flex-start"
                  alignItems="center"
                >
                  <IconButton
                    onClick={() => selectHandler(`${index}`, "feeling")}
                  >
                    <Avatar
                      alt="feeling"
                      src={img}
                      sx={{ width: 50, height: 50 }}
                      style={{
                        border:
                          activeButton === `${index}`
                            ? "3px solid#f8a540"
                            : "1px solid lightgray",
                      }}
                    ></Avatar>
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ height: 10 }}></Box>
          <Typography sx={{ color: "black", fontSize: 15 }}>
            おみくじ
          </Typography>
          <Box sx={{ height: 5 }}></Box>
          <Grid container>
            {omikuji.map((text, index) => (
              <Grid item xs={3} key={index}>
                <Box
                  display="flex"
                  justifyContent="center"
                  justify="flex-start"
                  alignItems="center"
                >
                  <IconButton
                    onClick={() => selectHandler(`${index}`, "omikuji")}
                  >
                    <Avatar
                      alt="feeling"
                      style={{
                        border:
                          activeOmikuji === `${index}`
                            ? "3px solid#f8a540"
                            : "1px solid lightgray",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      sx={{ width: 50, height: 50 }}
                    >
                      {text}
                    </Avatar>
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ height: 10 }}></Box>
          <Typography sx={{ color: "black", fontSize: 15 }}>日記</Typography>
          <Box sx={{ height: 5 }}></Box>

          <TextField
            autoFocus={false}
            multiline
            rows={8}
            sx={{ width: "100%" }}
            placeholder={placeholder_text}
            value={value}
            onChange={(event, newValue) => {
              setValue(event.target.value);
            }}
            inputProps={{ style: { fontSize: 18 } }}
          ></TextField>
          <Box sx={{ height: 10 }}></Box>

          <Box sx={{ height: 10 }}></Box>
          <Typography sx={{ color: "black", fontSize: 15 }}>
            気持ちの種類
          </Typography>
          <Box sx={{ height: 15 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Grid container justifyContent={"flex-start"}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  style={{
                    width: "90%",
                    height: 40,
                    borderWidth: "3px",
                    borderColor: harauButton === true ? "#773dff" : "lightgray",
                  }}
                  onClick={() => handleharau(!harauButton)}
                >
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
                  >
                    祓いたいこと
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  style={{
                    width: "90%",
                    height: 40,
                    borderWidth: "3px",
                    borderColor:
                      kanaeruButton === true ? "#ffe53d" : "lightgray",
                    // "&.Mui-selected": { backgroundColor: "white" },
                  }}
                  onClick={() => handlekanaeru(!kanaeruButton)}
                >
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
                  >
                    叶えたいこと
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Box sx={{ height: 40 }}></Box>

          <Divider sx={{ width: "100%", mb: 2 }}></Divider>

          <Box sx={{ height: 20 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              sx={{ width: "60%", height: 40, backgroundColor: "#ffa94d" }}
              onClick={() => sendDOpen()}
            >
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", color: "white" }}
              >
                記録する
              </Typography>
            </Button>
            <Box sx={{ height: 20 }}></Box>
            <Button
              variant="contained"
              sx={{
                width: "60%",
                height: 40,
                color: "black",
                backgroundColor: "white",
              }}
              onClick={() => props.CloseD()}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                キャンセル
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ height: 80 }}></Box>
      </Grid>
      <Dialog onClose={sendDClose} open={sendD} sx={{ width: "100%" }}>
        <Box sx={{ height: 40 }}></Box>
        <Grid p={2}>
          <Typography
            sx={{ fontSize: 16, fontWeight: "bold" }}
            mt={2}
            mr={2}
            ml={2}
          >
            記録してもよろしいですか？
          </Typography>
          <Box sx={{ height: 40 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#ffa94d",
                color: "white",
                width: "70%",
                height: 40,
              }}
              onClick={() => onSubmit()}
            >
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", color: "white" }}
              >
                記録する
              </Typography>
            </Button>
            <Box sx={{ height: 20 }}></Box>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => sendDClose()}
              style={{ width: "70%", height: 40 }}
            >
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
              >
                キャンセル
              </Typography>
            </Button>
          </Grid>
          <Box sx={{ height: 40 }}></Box>
        </Grid>
      </Dialog>

      <Dialog onClose={confirmDClose} open={confirmD} sx={{ width: "100%" }}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
          p={1}
        >
          <Box sx={{ height: 20 }}></Box>
          <Typography
            sx={{ fontSize: 16, fontWeight: "bold" }}
            mt={2}
            mr={2}
            ml={2}
          >
            記録しました
          </Typography>
          <Box sx={{ height: 10 }}></Box>

          <Contact></Contact>

          <Box sx={{ height: 40 }}></Box>

          <Button
            variant="contained"
            style={{
              backgroundColor: "white",
              color: "black",
              width: "70%",
            }}
            onClick={() => escape()}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              もどる
            </Typography>
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>

      <Dialog onClose={alertDClose} open={alertD} sx={{ width: "100%" }}>
        <Box sx={{ height: 40 }}></Box>
        <Grid p={2}>
          <Typography sx={{ fontSize: 14 }} mt={2} mr={2} ml={2}>
            ご利用には、新規登録・ログインが必要です
          </Typography>
          <Box sx={{ height: 40 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#ffa94d",
                color: "white",
                width: "70%",
              }}
              onClick={() => goSignup()}
            >
              登録・ログイン
            </Button>
            <Box sx={{ height: 20 }}></Box>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => alertDClose()}
              style={{ width: "70%" }}
            >
              キャンセル
            </Button>
          </Grid>
          <Box sx={{ height: 40 }}></Box>
        </Grid>
      </Dialog>

      <Dialog
        onClose={alreadyDClose}
        open={already_recordD}
        sx={{ width: "100%" }}
      >
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
          p={3}
        >
          <Box sx={{ height: 20 }}></Box>
          <Typography
            sx={{ fontSize: 16, fontWeight: "bold" }}
            mt={2}
            mr={2}
            ml={2}
          >
            本日は既に記録されています
          </Typography>

          <Box sx={{ height: 40 }}></Box>

          <Button
            variant="contained"
            style={{
              backgroundColor: "white",
              color: "black",
              width: "70%",
            }}
            onClick={() => alreadyDClose()}
          >
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              もどる
            </Typography>
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>
    </Grid>
  );
}
