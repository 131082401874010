import { React, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createContext } from "react";

import Thanks from "../Thanks.js";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Goukaku_1 from "./Goukaku_1.js";
import Goukaku_2 from "./Goukaku_2.js";
import Goukaku_3 from "./Goukaku_3.js";

import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import torii from "../../images/torii.png";
import oonusa from "../../images/oonusa.png";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { useNavigate, Navigate, Link } from "react-router-dom";
import BottomMenu from "../Sub/BottomMenu.js";

export const OtherApplyData = createContext();

function getSteps() {
  return ["選択", "日時", "確認"];
}

const BaseOfGoukaku = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [currentState, setCurrentState] = useState();

  const navigate = useNavigate();
  const value = {
    currentState,
    setCurrentState,
  };

  const steps = getSteps();
  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiStepIcon-active": { color: "#ffa94d" },
      "& .MuiStepIcon-completed": { color: "#ffa94d" },
    },
  }));

  const c = useStyles();

  const handleNext = () => {
    const newPage = activeStep + 1;
    setActiveStep(newPage);
  };
  const handleBack = () => {
    const newPage = activeStep - 1;
    setActiveStep(newPage);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const goHome = () => {
    navigate("/");
    console.log("goHome was called");
  };

  const goThanks = () => {
    navigate("/thanks");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Goukaku_1
            handleNext={handleNext}
            goHome={goHome}
            accounts={props.accounts}
          ></Goukaku_1>
        );

      case 1:
        return (
          <Goukaku_2
            handleNext={handleNext}
            handleBack={handleBack}
          ></Goukaku_2>
        );
      case 2:
        return (
          <Goukaku_3
            handleNext={handleNext}
            handleBack={handleBack}
          ></Goukaku_3>
        );
      // case 3:
      //     return <Apply4 handleNext={handleNext} handleBack={handleBack}></Apply4>;
      //  case 4:
      //     return <Apply5  handleNext={goThanks} handleBack={handleBack}></Apply5>;
      default:
        return "Unknown stepIndex";
    }
  }

  const CustomisedBottomNavigationAction = (props) => (
    <BottomNavigationAction sx={{ minWidth: "70px" }} {...props} />
  );
  return (
    <Grid>
      {activeStep === steps.length ? (
        <div>
          <Thanks handleBack={handleBack} goHome={goHome}></Thanks>
        </div>
      ) : (
        <div>
          <Box sx={{ height: 20 }}></Box>

          <Grid item={true} mb={2} />
          <Grid item={true} lg={8} sm={8}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className={c.root}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>
                    <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Box sx={{ height: 10 }}></Box>

          <OtherApplyData.Provider value={value}>
            {getStepContent(activeStep)}
          </OtherApplyData.Provider>
        </div>
      )}
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={8}
          onChange={(event, newValue) => {
            // setBottomV(newValue);
            // console.log("newValue: " + newValue);
            if (newValue === 0) {
            }
          }}
        >
          <CustomisedBottomNavigationAction
            icon={<img src={torii} width={25} />}
            component={Link}
            label="ホーム"
            to="/"
          />
          {/* <CustomisedBottomNavigationAction
            icon={
              <AutoStoriesOutlinedIcon
                sx={{ fontSize: 25, color: "#5D5D5D" }}
              />
            }
            component={Link}
            label={<Typography sx={{ fontSize: 11 }}>まめ日記</Typography>}
            to="/"
          /> */}
          <CustomisedBottomNavigationAction
            icon={
              <LightbulbOutlinedIcon sx={{ fontSize: 26, color: "#5D5D5D" }} />
            }
            component={Link}
            label="クイズ"
            to="/quiz"
          />
          <CustomisedBottomNavigationAction
            icon={
              <AutoStoriesOutlinedIcon
                sx={{ fontSize: 27, color: "#5D5D5D" }}
              />
            }
            component={Link}
            label="御朱印"
            to="/kamidana"
          />
          <CustomisedBottomNavigationAction
            icon={<img src={oonusa} width={26} />}
            label="ご祈祷"
            component={Link}
            to="/kigan"
          />

          {/* <BottomNavigationAction
            icon={<SettingsOutlinedIcon sx={{ fontSize: 25 }} />}
            component={Link}
            label="設定"
            to="/setting"
          /> */}
        </BottomNavigation>
      </Paper>
    </Grid>
  );
};

export default BaseOfGoukaku;
