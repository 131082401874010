import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Fab from "@mui/material/Fab";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { motion } from "framer-motion";

export default function Koyomi(props) {
  const navigate = useNavigate();
  const [moon_img, setMoonImage] = useState(props.moon_img);
  const [moon_age, setMoonAge] = useState(props.moon_age);
  const [koyomi_deta, setKoyomiDeta] = useState(props.koyomi_dic);
  const [sekki, set_sekki] = useState("");

  const goDialy = () => {
    navigate("/dialy");
  };

  useLayoutEffect(() => {
    Get24Sekki();
  }, []);

  const Get24Sekki = () => {
    const today = new Date();
    const today_month = today.getMonth();
    let result_sekki = "";

    // 〈春〉
    const sekki1 = new Date(2023, 1, 4, 11, 43); // 1. 立春（りっしゅん）　02月04日
    const sekki2 = new Date(2023, 1, 19, 7, 34); // 2. 雨水（うすい）　02月19日
    const sekki3 = new Date(2023, 2, 6, 5, 36); // 3. 啓蟄（けいちつ）　03月06日
    const sekki4 = new Date(2023, 2, 21, 6, 24); // 4. 春分（しゅんぶん）　03月21日
    const sekki5 = new Date(2023, 3, 5, 10, 13); // 5. 清明（せいめい）04月05日
    const sekki6 = new Date(2023, 3, 20, 17, 14); // 6. 穀雨（こくう）　04月20日

    // 〈夏〉
    const sekki7 = new Date(2023, 4, 6, 3, 19); // 7. 立夏（りっか）	05月06日
    const sekki8 = new Date(2023, 4, 21, 16, 9); // 8. 小満（しょうまん）　05月21日
    const sekki9 = new Date(2023, 5, 6, 7, 18); // 9. 芒種（ぼうしゅ）　06月06日
    const sekki10 = new Date(2023, 5, 21, 23, 58); // 10. 夏至（げし）　06月21日
    const sekki11 = new Date(2023, 6, 7, 17, 31); // 11. 小暑（しょうしょ）　07月07日
    const sekki12 = new Date(2023, 6, 23, 10, 50); // 12. 大暑（たいしょ）　07月23日

    // 〈秋〉
    const sekki13 = new Date(2023, 7, 8, 3, 23); // 13. 立秋（りっしゅう）　08月08日
    const sekki14 = new Date(2023, 7, 23, 18, 1); // 14. 処暑（しょしょ）　08月23日
    const sekki15 = new Date(2023, 8, 8, 6, 27); // 15. 白露（はくろ）　09月08日
    const sekki16 = new Date(2023, 8, 23, 15, 50); // 16. 秋分（しゅうぶん）　09月23日
    const sekki17 = new Date(2023, 9, 8, 22, 16); // 17. 寒露（かんろ）　10月08日
    const sekki18 = new Date(2023, 9, 24, 1, 21); // 18. 霜降（そうこう）　10月24日

    // 〈冬〉
    const sekki19 = new Date(2023, 10, 7, 1, 36); // 19. 立冬（りっとう）　11月07日
    const sekki20 = new Date(2023, 10, 22, 23, 3); // 20. 小雪（しょうせつ）　11月22日
    const sekki21 = new Date(2023, 11, 7, 18, 33); // 21. 大雪（たいせつ）　12月07日
    const sekki22 = new Date(2023, 11, 22, 12, 27); // 22. 冬至（とうじ）　12月22日
    const sekki23 = new Date(2023, 0, 6, 0, 5); // 23. 小寒（しょうかん）　01月06日
    const sekki24 = new Date(2023, 0, 20, 17, 30); // 24. 大寒（だいかん）　01月20日

    if (today_month >= 0 && today_month <= 5) {
      if (CompareSekki(today, sekki24, sekki1)) {
        result_sekki = "立春(りっしゅん)";
      } else if (CompareSekki(today, sekki1, sekki2)) {
        result_sekki = "雨水(うすい)";
      } else if (CompareSekki(today, sekki2, sekki3)) {
        result_sekki = "啓蟄(けいちつ)";
      } else if (CompareSekki(today, sekki3, sekki4)) {
        result_sekki = "春分(しゅんぶん)";
      } else if (CompareSekki(today, sekki4, sekki5)) {
        result_sekki = "清明(せいめい)";
      } else if (CompareSekki(today, sekki5, sekki6)) {
        result_sekki = "穀雨(こくう)";
      }
    }
    if (today_month >= 3 && today_month <= 8) {
      if (CompareSekki(today, sekki6, sekki7)) {
        result_sekki = "立夏(りっか)";
      } else if (CompareSekki(today, sekki7, sekki8)) {
        result_sekki = "小満(しょうまん)";
      } else if (CompareSekki(today, sekki8, sekki9)) {
        result_sekki = "芒種(ぼうしゅ)";
      } else if (CompareSekki(today, sekki9, sekki10)) {
        result_sekki = "夏至(げし)";
      } else if (CompareSekki(today, sekki10, sekki11)) {
        result_sekki = "小暑(しょうしょ)";
      } else if (CompareSekki(today, sekki11, sekki2)) {
        result_sekki = "大暑(たいしょ)";
      }
    }

    if (today_month >= 6 && today_month <= 11) {
      if (CompareSekki(today, sekki12, sekki13)) {
        result_sekki = "立秋(りっしゅう)";
      } else if (CompareSekki(today, sekki13, sekki14)) {
        result_sekki = "処暑(しょしょ)";
      } else if (CompareSekki(today, sekki14, sekki15)) {
        result_sekki = "白露(はくろ)";
      } else if (CompareSekki(today, sekki15, sekki16)) {
        result_sekki = "秋分(しゅうぶん)";
      } else if (CompareSekki(today, sekki16, sekki17)) {
        result_sekki = "寒露(かんろ)";
      } else if (CompareSekki(today, sekki17, sekki18)) {
        result_sekki = "霜降(そうこう)";
      }
    }

    if (today_month >= 9 || today_month <= 1) {
      if (CompareSekki(today, sekki18, sekki19)) {
        result_sekki = "立冬(りっとう)";
      } else if (CompareSekki(today, sekki19, sekki20)) {
        result_sekki = "小雪(しょうせつ)";
      } else if (CompareSekki(today, sekki20, sekki21)) {
        result_sekki = "大雪(たいせつ)";
      } else if (CompareSekki(today, sekki21, sekki22)) {
        result_sekki = "冬至(とうじ)";
      } else if (CompareSekki(today, sekki22, sekki23)) {
        result_sekki = "小寒(しょうかん)";
      } else if (CompareSekki(today, sekki23, sekki24)) {
        result_sekki = "大寒(だいかん)";
      }
    }
    // console.log(
    //   "今日は、２４節気のうち「" + result_sekki + "」の区分にあります　"
    // );
    set_sekki(result_sekki);
  };

  const CompareSekki = (today, beforesekki, aftersekki) => {
    return today >= beforesekki && today <= aftersekki;
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      textAlign={"left"}
      sx={{ width: "100%", mb: 4 }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        textAlign={"left"}
        sx={{ width: "90%", mb: 2 }}
      >
        <Grid item xs={6}>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            本日の暦
          </Typography>
        </Grid>
      </Grid>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{ width: "90%" }}
      >
        <Grid container alignItems="center" justifyContent="center">
          <Card sx={{ width: "100%" }}>
            <CardContent sx={{ p: 1, px: 0.5, "&:last-child": { pb: 1 } }}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                textAlign="left"
                pl={1}
                pr={1}
              >
                <Grid item xs={8}>
                  <Typography
                    sx={{ fontSize: 15, mt: 1, fontWeight: "bold" }}
                    variant="body2"
                  >
                    {"令和6年" +
                      koyomi_deta["month"] +
                      "月" +
                      koyomi_deta["day"] +
                      "日"}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 15, mt: 1, fontWeight: "bold" }}
                    variant="body2"
                  >
                    陰暦: {koyomi_deta["inreki"]}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 15, mt: 1, fontWeight: "bold" }}
                    variant="body2"
                  >
                    十干十二支: {koyomi_deta["zyusi"]}
                    {koyomi_deta["zyunisi"]}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 15, mt: 1, fontWeight: "bold" }}
                    variant="body2"
                  >
                    二十四節気: {sekki}
                  </Typography>
                  {/* <Typography
                sx={{ fontSize: 15, mt: 1, fontWeight: "bold" }}
                variant="body2"
              >
                雑節:
              </Typography> */}
                </Grid>
                <Grid item xs={4}>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    direction="column"
                    textAlign={"left"}
                  >
                    <img src={moon_img} width="90" height="90"></img>
                    <Typography
                      sx={{ fontSize: 15, fontWeight: "bold", mt: 1 }}
                    >
                      月齢: {moon_age}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </motion.div>
    </Grid>
  );
}
