import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Typography from "@mui/material/Typography";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Fab from "@mui/material/Fab";
import { getAuth } from "firebase/auth";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Drawer from "@mui/material/Drawer";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Camera from "../../AchieveComponent/Camera.js";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Select from "@mui/material/Select";

import kanni from "../../images/kanni.jpg";
import issyakamidana from "../../images/issyakamidana.jpg";
import sansyakamidana from "../../images/sansyakamidana.jpg";
import kisohinokikamidana from "../../images/kisohinokikamidana.jpg";
import honkanagukamidana from "../../images/honkanagukamidana.jpg";
import keyakisyokukamidana from "../../images/keyakisyokukamidana.jpg";
import gosyakamidana from "../../images/gosyakamidana.jpg";
import hakomiyakamidana from "../../images/hakomiyakamidana.jpg";

//自分の神棚の種類を表示する
//タップすると拡大

export default function MyKamidana(props) {
  const navigate = useNavigate();
  const [imgD, setImgD] = useState(false);
  const [img_index, setImg_index] = useState(0);
  const [openCameraD, set_openCameraD] = useState(false);
  const [cameraD, set_cameraD] = useState(false);
  const [QR_text, set_QR_text] = useState("");
  const [kamidana, setKamidana] = useState("なし");
  const [kamidana_img, set_kamidana_img] = useState("");
  const [update, setUpdate] = useState(false);
  const [updateCompleteD, SetupdateCompleteD] = useState(false);
  const [url, set_url] = useState("");

  const [alert, set_alert] = useState(false);

  const kamidana_menu = [
    { label: "なし", value: "なし" },
    { label: "簡易宮型", value: "簡易宮型" },
    { label: "木曽檜神棚", value: "木曽檜神棚" },
    { label: "本金具神棚", value: "本金具神棚" },
    { label: "けやき色神棚", value: "けやき色神棚" },
    { label: "三社神棚", value: "三社神棚" },
    { label: "一社神棚", value: "一社神棚" },
    { label: "五社神棚", value: "五社神棚" },
    { label: "箱宮神棚", value: "箱宮神棚" },
  ];

  const Handle_alertD = (open) => {
    set_alert(open);
  };

  useEffect(() => {}, [img_index, QR_text]);

  useLayoutEffect(() => {
    GetKamidanaDeta();
  }, []);

  //TODO - 選択している神棚が更新されたら、DBも更新する
  const CheckUpdate = async () => {
    if (props.document_id == "") {
      //アラートを表示
      Handle_alertD(true);

      return;
    }
    if (update) {
      const kamidanaRef = doc(db, "account_list", props.document_id);
      const deta = { kamidana: kamidana };
      await updateDoc(kamidanaRef, deta);
      handleUpdateCompleteD(true);
    }
  };

  const handleKamidana = (type) => {
    //選択した神棚を表示する
    switch (type) {
      case "簡易宮型":
        set_kamidana_img(kanni);
        set_url("");
        break;
      case "木曽檜神棚":
        set_kamidana_img(kisohinokikamidana);
        // set_url("https://kamidananosato.jp/?mode=cate&cbid=2435642&csid=14");
        break;
      case "本金具神棚":
        set_kamidana_img(honkanagukamidana);
        set_url();
        // "https://kamidananosato.jp/?mode=srh&keyword=%CB%DC%B6%E2%B6%F1"
        break;
      case "けやき色神棚":
        set_kamidana_img(keyakisyokukamidana);
        // set_url("https://kamidananosato.jp/?mode=grp&gid=1932177");
        break;
      case "三社神棚":
        set_kamidana_img(sansyakamidana);
        // set_url("https://kamidananosato.jp/?mode=cate&cbid=2435642&csid=1");
        break;
      case "一社神棚":
        set_kamidana_img(issyakamidana);
        // set_url("https://kamidananosato.jp/?mode=cate&cbid=2435642&csid=2");
        break;
      case "五社神棚":
        set_kamidana_img(gosyakamidana);
        // set_url("https://kamidananosato.jp/?mode=cate&cbid=2435642&csid=3");
        break;
      case "箱宮神棚":
        set_kamidana_img(hakomiyakamidana);
        // set_url("https://kamidananosato.jp/?mode=cate&cbid=2435642&csid=8");
        break;

      default:
        set_kamidana_img("");
        set_url("");
        break;
    }
  };

  const GetKamidanaDeta = async () => {
    try {
      let index = 0;
      let kamidana_values = [];
      const kamidanaRef = doc(db, "account_list", props.document_id);

      const gosyuinSnaps = await getDoc(kamidanaRef);
      const values = gosyuinSnaps.data();
      if (typeof values["kamidana"] !== "undefined") {
        kamidana_values = values["kamidana"];
      }

      if (kamidana_values !== []) {
        handleKamidana(kamidana_values);
        setKamidana(kamidana_values);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const cameraDHandler = (open) => {
    set_cameraD(open);
  };

  const opencameraDHandler = (open) => {
    set_openCameraD(open);
  };

  const handleimgD = (open, i) => {
    setImgD(open);
    // setImg_index(i);
  };

  const goSignup = () => {
    navigate("/signup");
  };

  const handleUpdateCompleteD = (open) => {
    SetupdateCompleteD(open);
    setUpdate(false);
  };

  const openurl = (url) => {
    window.open(url, "_blank");
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiOutlinedInput-input": {
        border: 0,
        borderRadius: 3,
        fontSize: 20,
        fontWeight: "bold",
      },
    },
  }));
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"left"}
    >
      <Box sx={{ height: 40 }}></Box>

      {/* <Divider sx={{ width: "90%", mb: 2 }}></Divider> */}

      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        textAlign={"left"}
        spacing={2}
      >
        <Grid item xs={6}>
          <Typography sx={{ ml: 3, fontSize: 18, fontWeight: "bold" }}>
            あなたの神棚
          </Typography>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="row"
        textAlign={"left"}
        spacing={2}
        p={2}
      >
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <Card>
            <CardActionArea onClick={() => handleimgD(true)}>
              <CardContent sx={{ p: 2, "&:last-child": { pb: 1 } }}>
                <Grid container alignItems="center" justifyContent="center">
                  {kamidana_img !== "" && (
                    <React.Fragment>
                      <img src={kamidana_img} height="204"></img>
                      {url !== "" && (
                        <Box
                          onClick={() => openurl(url)}
                          sx={{
                            borderBottom: 1,
                            borderColor: "#768ef1",
                            mt: 2,
                          }}
                        >
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  color: "#768ef1",
                                }}
                              >
                                神棚の里で選ぶ
                              </Typography>
                            </Grid>
                            <Grid item>
                              <OpenInNewIcon
                                sx={{
                                  color: "#768ef1",
                                  fontSize: 18,
                                  ml: 0.5,
                                  mt: 1,
                                }}
                              ></OpenInNewIcon>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </React.Fragment>
                  )}
                  {kamidana_img === "" && (
                    <Grid
                      container
                      sx={{
                        height: 204,
                        width: 204,
                        backgroundColor: "#ece9e9f2",
                      }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                        まだ神棚を用意していません
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            textAlign={"center"}
            mt={3}
            mb={2}
          >
            <TextField
              select
              defaultValue="なし"
              fullWidth
              className={classes.root}
              sx={{ width: "60%", height: 55 }}
              onChange={(e) => {
                setKamidana(e.target.value);
                handleKamidana(e.target.value);
                setUpdate(true);
              }}
            >
              {kamidana_menu.map(({ label, value }, index) => (
                <MenuItem key={index} value={value}>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    {value}
                  </Typography>
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {update && (
            <Grid
              container
              alignItems="center"
              justifyContent={"center"}
              mt={2}
            >
              <Button
                variant="contained"
                sx={{ height: 40, width: 140, backgroundColor: "#ffa94d" }}
                onClick={() => CheckUpdate()}
              >
                <Typography
                  sx={{ fontSize: 15, fontWeight: "bold", color: "white" }}
                >
                  更新する
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Box sx={{ height: 20 }}></Box>
      <Dialog
        onClose={() => handleUpdateCompleteD(false)}
        open={updateCompleteD}
      >
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"left"}
          p={3}
        >
          <DialogTitle>
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              更新しました
            </Typography>
          </DialogTitle>
          <Box sx={{ height: 20 }}></Box>

          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              color="inherit"
              sx={{
                width: "60%",
                height: 40,
                backgroundColor: "#ece9e9f2",
                color: "black",
              }}
              onClick={() => handleUpdateCompleteD(false)}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                もどる
              </Typography>
            </Button>
            <Box sx={{ height: 20 }}></Box>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        onClose={() => Handle_alertD(false)}
        open={alert}
        sx={{ width: "100%" }}
      >
        <Box sx={{ height: 40 }}></Box>
        <Grid p={2}>
          <Typography
            sx={{ fontSize: 16, fontWeight: "bold" }}
            mt={2}
            mr={2}
            ml={2}
          >
            ご利用には、新規登録・ログインが必要です
          </Typography>
          <Box sx={{ height: 40 }}></Box>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#ffa94d",
                color: "white",
                width: "70%",
                height: 40,
              }}
              onClick={() => goSignup()}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                登録・ログイン
              </Typography>
            </Button>
            <Box sx={{ height: 20 }}></Box>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => Handle_alertD(false)}
              style={{ width: "70%" }}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                もどる
              </Typography>
            </Button>
          </Grid>
          <Box sx={{ height: 40 }}></Box>
        </Grid>
      </Dialog>
    </Grid>
  );
}
