import { React, useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { auth } from "../firebase";
import { useAuthContext } from "../context/ AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db, app } from "../firebase";
import { collection, onSnapshot, addDoc, doc } from "firebase/firestore";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import LoginIcon from "@mui/icons-material/Login";
import {
  GoogleAuthProvider,
  signInWithRedirect,
  FacebookAuthProvider,
} from "firebase/auth";
import { Link as RouterLink } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Dialog } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import IconButton from "@mui/material/IconButton";

import Paper from "@mui/material/Paper";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import torii from "../images/torii.png";
import oonusa from "../images/oonusa.png";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import BottomMenu from "./Sub/BottomMenu";

const provider = new GoogleAuthProvider();

const SignUp = (props) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const provider = new GoogleAuthProvider();
  const facebook_provider = new FacebookAuthProvider();

  const [signupwithmailD, SetSignupWithMail] = useState(false);

  const useStyles = makeStyles((theme) => ({
    GoogleButton: {
      textTransform: "none",
    },
    root: {
      "& .MuiOutlinedInput-input": {
        border: 0,
        borderRadius: 3,
        fontSize: 18,
        fontWeight: "bold",
      },
    },
  }));

  const classes = useStyles();

  const handlesignup = (open) => {
    SetSignupWithMail(open);
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      mailBox: "",
      passwordBox: "",
    },
  });

  const handleBack = (action) => {
    if (action === "back") {
      navigate("/login");
      console.log("go login page");
    }
  };

  const addDb = async (id) => {
    const ref = collection(db, "account_list");
    const documentRef = await addDoc(ref, {
      uid: id,
    });
    console.log(documentRef);
  };

  const handleLogin = async (event) => {
    signInWithRedirect(auth, provider);
    // navigate("/");
  };

  const facebookLogin = async (event) => {
    signInWithRedirect(auth, facebook_provider);
  };

  const onSubmit = async (data) => {
    try {
      await auth.createUserWithEmailAndPassword(data.mailBox, data.passwordBox);
      const uid = auth.currentUser.uid;
      console.log("uid: " + uid);
      addDb(uid);
      // setCurrentState({ ...currentState, "applyed_user": false });

      console.log(
        data.mailBox + "と" + data.passwordBox + "でアカウントを作成しました"
      );
      navigate("/signupcomplete");
    } catch (e) {
      console.log(e);
      switch (e.code) {
        case "auth/network-request-failed":
          setError(
            "通信がエラーになったのか、またはタイムアウトになりました。通信環境がいい所で再度やり直してください。"
          );
          break;
        case "auth/weak-password": //バリデーションでいかないようにするので、基本的にはこのコードはこない
          setError("パスワードが短すぎます。6文字以上を入力してください。");
          break;
        case "auth/invalid-email": //バリデーションでいかないようにするので、基本的にはこのコードはこない
          setError("メールアドレスが正しくありません");
          break;
        case "auth/email-already-in-use":
          setError(
            "メールアドレスがすでに使用されています。ログインするか別のメールアドレスで作成してください"
          );
          break;
        default: //想定外
          setError(
            "アカウントの作成に失敗しました。通信環境がいい所で再度やり直してください。"
          );
      }
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      textAlign={"left"}
    >
      <Box sx={{ height: 60 }}></Box>
      <Typography sx={{ fontSize: 28, fontWeight: "bold", color: "#ffa94d" }}>
        参拝のおとも
      </Typography>
      <Box sx={{ height: 40 }}></Box>

      <Button
        variant="contained"
        style={{
          backgroundColor: "#ffffff",
          color: "black",
          textTransform: "capitalize",
        }}
        type="submit"
        onClick={() => handlesignup(true)}
        sx={{ width: "260px", height: 55 }}
        startIcon={<EmailOutlinedIcon />}
      >
        <Typography
          sx={{ fontSize: 16, fontWeight: "bold" }}
          className={classes.GoogleButton}
        >
          メールアドレスではじめる
        </Typography>
      </Button>
      <Box sx={{ height: 20 }}></Box>

      <Button
        variant="contained"
        style={{
          backgroundColor: "#ffffff",
          color: "black",
          textTransform: "capitalize",
        }}
        type="submit"
        onClick={() => {
          facebookLogin();
        }}
        sx={{ width: "260px", height: 55 }}
        startIcon={
          <Avatar
            src={
              "https://upload.wikimedia.org/wikipedia/commons/b/b8/2021_Facebook_icon.svg"
            }
            className={classes.GoogleButton}
            sx={{ width: 25, height: 25 }}
          />
        }
      >
        <Typography
          sx={{ fontSize: 16, fontWeight: "bold" }}
          className={classes.GoogleButton}
        >
          Facebookではじめる
        </Typography>
      </Button>
      <Box sx={{ height: 20 }}></Box>

      <Button
        variant="contained"
        style={{
          backgroundColor: "#ffffff",
          color: "black",
          textTransform: "capitalize",
        }}
        type="submit"
        onClick={() => {
          handleLogin();
        }}
        sx={{ width: "260px", height: 55 }}
        startIcon={
          <Avatar
            src={
              "https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
            }
            className={classes.GoogleButton}
            sx={{ width: 25, height: 25 }}
          />
        }
      >
        <Typography
          sx={{ fontSize: 16, fontWeight: "bold" }}
          className={classes.GoogleButton}
        >
          Googleではじめる
        </Typography>
      </Button>
      <Box sx={{ height: 30 }}></Box>

      <Typography
        sx={{ fontSize: 18, fontWeight: "bold", color: "black" }}
        mb={1}
      >
        アカウントをお持ちの方はこちら
      </Typography>
      <Button
        variant="contained"
        sx={{ width: "260px", height: 55 }}
        style={{ backgroundColor: "#ffa94d", color: "white" }}
        onClick={() => handleBack("back")}
      >
        <Typography sx={{ fontSize: 18, fontWeight: "bold" }} mr={1}>
          ログインする
        </Typography>
      </Button>

      <Box sx={{ height: 20 }}></Box>
      <Grid sx={{ width: "260px" }} mt={2} mb={4}>
        <Typography sx={{ fontSize: 14, color: "gray" }}>
          参拝のおともを利用することで
          <Link
            href="#"
            color="inherit"
            component={RouterLink}
            to={"/terms"} //遷移先
            underline="none"
          >
            {"利用規約・プライバシーポリシー"}
          </Link>
          に同意することとします。
        </Typography>
      </Grid>
      <Box sx={{ height: 80, mb: 1 }}></Box>

      <Dialog
        onClose={() => handlesignup(false)}
        fullScreen
        open={signupwithmailD}
      >
        <Box sx={{ height: 40 }}></Box>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          ml={3}
          sx={{
            backgroundColor: "#ece9e9f2",
            width: 120,
            height: 44,
            borderRadius: 2,
          }}
        >
          <IconButton onClick={() => handlesignup(false)}>
            <ArrowBackIosNewOutlinedIcon
              sx={{ color: "black" }}
            ></ArrowBackIosNewOutlinedIcon>
            <Typography sx={{ fontSize: 16, fontWeight: "bold", ml: 1 }}>
              もどる
            </Typography>
          </IconButton>
        </Grid>
        <Box sx={{ height: 80 }}></Box>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            新規登録
          </Typography>
        </Grid>
        <Box sx={{ height: 40 }}></Box>

        {error && <p style={{ color: "red" }}>{error}</p>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            textAlign={"center"}
            mb={2}
          >
            <Controller
              control={control}
              name="mailBox"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="メールアドレス"
                  fullWidth
                  margin="normal"
                  placeholder="メールアドレス"
                  sx={{ width: "260px" }}
                  className={classes.root}
                />
              )}
            />
            <Controller
              control={control}
              name="passwordBox"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="パスワード"
                  fullWidth
                  margin="normal"
                  type="password"
                  placeholder="パスワード"
                  sx={{ width: "260px" }}
                  className={classes.root}
                />
              )}
            />

            <Box sx={{ height: 20 }}></Box>

            <Button
              variant="contained"
              style={{ backgroundColor: "#ffa94d", color: "white" }}
              type="submit"
              sx={{ width: "260px", height: 55 }}
            >
              <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                登録する
              </Typography>
            </Button>

            <Box sx={{ height: 15 }}></Box>
          </Grid>
        </form>
      </Dialog>

      <BottomMenu value={8} />
    </Grid>
  );
};

export default SignUp;
