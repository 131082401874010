import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { makeStyles } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import { useNavigate, Navigate, Link } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import gosyuin from "../../images/gosyuin.jpg";

const Gosyuin = (props) => {
  const { register, control, handleSubmit, getValues, setValues, setValue } =
    useForm({ defaultValues: { num: "1" } });
  const { fields, append, remove, replace, insert } = useFieldArray({
    name: "apply",
    control,
  });
  let check_arr = {};
  const [num, setNum] = useState("");

  //種類
  const [select_type, setSelect_type] = useState("種目1");
  //料金
  const [select_money, setselect_money] = useState("三千円");

  const accounts = props.accounts;
  // console.log("EasyApply1 props.accounts: " + JSON.stringify(accounts));
  const [data, setData] = useState([""]);
  const [phone_value, setPhoneValue] = useState("");
  const [email_value, setEmailValue] = useState("");
  const [confirmD, SetconfirmD] = useState(false);
  const [retryD, SetretryD] = useState(false);
  let checked = false;

  const navigate = useNavigate();

  useLayoutEffect(() => {
    setNum(props.accounts.length);
    //代表者の電話番号を取得したい
    for (let i = 0; i < props.accounts.length; i++) {
      if ("phone" in props.accounts[i]) {
        setPhoneValue(props.accounts[i].phone);
      }
      if ("email" in props.accounts[i]) {
        setEmailValue(props.accounts[i].email);
      }
    }
    const values = [];
    console.log("num: " + props.accounts.length);
    const form_data = getValues();
    console.log("field value -> " + JSON.stringify(form_data));
    for (let i = 0; i < props.accounts.length; i++) {
      const value = { selected: false, index: i };
      values.push(value);
    }
    setData(values);

    // console.log("data: " + JSON.stringify(data));
  }, []);

  console.log("--------------Rendered--------------------");

  useEffect(() => {
    if (props.accounts == "") {
      navigate("/");
    }
  }, []);

  const handleCheck = (e, index) => {
    console.log("index: " + index);

    data[index]["selected"] = e.target.checked;

    //空辞書を削除する
    const unformated_data = getValues();
    const formated_data = deleteEnpty(unformated_data.apply);
    replace(formated_data);
    console.log("format_data: " + JSON.stringify(formated_data));

    //選択した時
    if (e.target.checked) {
      checked = true;
      const before_data = getValues();
      console.log("before_data: " + JSON.stringify(before_data));

      if (index === 0) {
        insert(0, {
          index: index,
          pray1: "安産祈願",
          pray2: "身体堅固",
          money: "五千円（小）",
        });
      } else {
        insert(index, {
          index: index,
          pray1: "安産祈願",
          pray2: "身体堅固",
          money: "五千円（小）",
        });
      }

      console.log("追加しました");

      const after_data = getValues();
      console.log("after_data: " + JSON.stringify(after_data));
    } else {
      //非選択の時
      const before_data = getValues();
      console.log("before_data: " + JSON.stringify(before_data));
      checked = false;

      if (index === 0) {
        remove(0);
      } else {
        //indexで検索して、その辞書を削除
        let remove_index;
        //replaceで置き換え
        for (let i = 0; i < before_data.apply.length; i++) {
          if (index === before_data.apply[i].index) {
            console.log("index hit!");
            remove_index = i;
          }
        }
        before_data.apply.splice(remove_index, 1);
        console.log(remove_index + " 番目を削除");
        replace(before_data.apply);
      }
      console.log("削除しました");
      const after_data = getValues();
      console.log("after_data: " + JSON.stringify(after_data));
    }
  };

  const goHome = () => {
    navigate("/");
  };

  const useStyles = makeStyles({
    startIcon: {
      position: "absolute",
      left: "1rem",
    },
  });

  const deleteEnpty = (dic) => {
    let format_data = [];
    //空の配列を削除する
    for (let i = 0; i < dic.length; i++) {
      if (Object.keys(dic[i]).length === 4) {
        format_data.push(dic[i]);
        // console.log("dic[i]: "+JSON.stringify(dic[i]));
        // console.log("空要素がありませんでした");
      } else {
        // console.log("空要素がありました")
      }
    }

    return format_data;
  };

  function isEmpty(obj) {
    return !Object.keys(obj).length;
  }

  const onSubmit = (action) => {
    //formデータとアカウント情報を紐付ける
    //フォームのデータ
    const num = fields.length;
    console.log("選択した人数-> " + num);
    const deta = getValues();
    const form_data = deleteEnpty(deta.apply);
    console.log("form_data: " + JSON.stringify(form_data));

    //もし選択してなかったら
    if (form_data.length > 0) {
      console.log("ある");
    } else {
      console.log("ない");
      console.log(checked);
      SetretryD(true);
      return;
    }

    console.log("form_data = " + JSON.stringify(form_data));
    const selected_index = [];

    //選択したアカウント分、紐付ける
    console.log("form_data.apply.length: " + form_data.length);
    for (let i = 0; i < form_data.length; i++) {
      selected_index.push(form_data[i].index);
    }

    console.log("selected index: " + selected_index);

    //currentStateに登録する情報
    let state_data;

    for (let i = 0; i < selected_index.length; i++) {
      //選択したアカウントの基本情報
      const account_data = [accounts[selected_index[i]]];
      console.log("account_data->" + JSON.stringify(account_data));
      account_data.push(form_data[i]);
      console.log("form_data: " + JSON.stringify(form_data[i]));
      if (i === 0) {
        state_data = [account_data];
      } else {
        state_data.push(account_data);
      }
    }

    console.log("phone_value => " + phone_value);
    console.log("state_data => " + JSON.stringify(state_data));

    // setCurrentState({
    //   ...currentState,
    //   num: num,
    //   phone: phone_value,
    //   email: email_value,
    //   EasyApply1: state_data,
    // });
    // console.log("currentState =  " + JSON.stringify(currentState));

    if (action === "back") {
      props.goHome();
    } else if (action === "next") {
      //申込を確定する
      //   props.handleNext();
    }
  };

  const setInputValue = (type, account_index, value) => {
    if (type === "money") {
      const data = getValues();
      let set_index;
      for (let i = 0; i < data.apply.length; i++) {
        //account_indexとフォームデータのindexが同じなら
        if (data.apply[i].index === account_index) {
          set_index = i;
        }
      }
      setValue(`apply.${set_index}.money`, value);
    } else if (type === "pray1") {
    } else if (type === "pray2") {
    }
  };

  const menuItems = [
    {
      label: "選択しない",
      value: "選択しない",
    },
    {
      label: "安産祈願",
      value: "安産祈願",
    },
    {
      label: "厄ばらい",
      value: "厄ばらい",
    },
    {
      label: "方位避け",
      value: "方位避け",
    },
    {
      label: "年祝い(寿歳)",
      value: "年祝い(寿歳)",
    },
    {
      label: "身体堅固",
      value: "身体堅固",
    },
    {
      label: "家内安全",
      value: "家内安全",
    },
    {
      label: "交通安全",
      value: "交通安全",
    },
    {
      label: "社内安全",
      value: "社内安全",
    },
    {
      label: "商売繁盛",
      value: "商売繁盛",
    },
    {
      label: "當病平癒",
      value: "當病平癒",
    },
    {
      label: "厄災悉除",
      value: "厄災悉除",
    },
    {
      label: "良縁成就",
      value: "良縁成就",
    },
    {
      label: "学芸身長",
      value: "学芸身長",
    },
    {
      label: "開運招福",
      value: "開運招福",
    },
  ];

  const money_menu = [
    {
      label: "三千円（普通）",
      value: "三千円（普通）",
    },
    {
      label: "三千円（小）",
      value: "三千円（小）",
    },
    {
      label: "五千円（普通）",
      value: "五千円（普通）",
    },
    {
      label: "五千円（小）",
      value: "五千円（小）",
    },
    {
      label: "壱万円（普通）",
      value: "壱万円（普通）",
    },
    {
      label: "壱万円（小）",
      value: "壱万円（小）",
    },
  ];

  //確認
  const confirmDOpen = () => {
    SetconfirmD(true);
  };
  const confirmDClose = () => {
    SetconfirmD(false);
  };

  const retryDClose = () => {
    SetretryD(false);
  };

  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"left"}
    >
      <Box sx={{ height: 10 }}></Box>
      <Card sx={{ width: "80%", minWidth: "300px" }}>
        <CardActionArea
          onClick={() => console.log("CardActionArea clicked")}
          component="a"
        >
          <CardMedia sx={{ height: 170 }} image={gosyuin} title="let's start" />
          <CardContent>
            <Typography sx={{ fontSize: 15 }}>
              感謝と想いを込めて、直接御朱印帳にお書きします。
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Box sx={{ height: 30 }}></Box>

      <Typography sx={{ fontSize: 20 }}>申込する人物を選択</Typography>
      <Box sx={{ height: 20 }}></Box>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"center"}
        pr={13}
      >
        <form>
          {accounts != "" &&
            accounts.map((data, i) => (
              <Grid container key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      name={`apply[${i}]`}
                      onChange={(e) => handleCheck(e, i)}
                      style={{ paddingRight: "20px" }}
                    />
                  }
                  label={accounts[i]["name"] + " 様"}
                />
                <Box sx={{ height: 40 }}></Box>
              </Grid>
            ))}
        </form>
      </Grid>

      <Box sx={{ height: 40 }}></Box>

      {/* <form onSubmit={handleSubmit(onSubmit)}>
        {accounts != "" &&
          accounts.map(
            (value, i) =>
              //選択されていた時のみ表示させたい
              data[0] !== "" &&
              data[i]["selected"] == true && (
                <Box key={i}>
                  <Grid>
                    <Box sx={{ backgroundColor: "#f7ad6c" }} p={1} mb={2}>
                      <Typography sx={{ fontSize: 20 }}>
                        {value["name"]}様
                      </Typography>
                    </Box>

                    {/* <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="info">
                          あなたは今年「厄年」ですので、〇〇を受けましょう
                        </Alert>
                      </Stack> */}
      {/* <Typography sx={{ fontSize: 18 }}>
                      願いの項目1つ目
                    </Typography>
                    <Grid>
                      <Controller
                        {...register(`apply.${i}.pray1`)}
                        control={control}
                        ref={null}
                        render={({ field }) => (
                          <div>
                            <Select
                              defaultValue="安産祈願"
                              fullWidth
                              sx={{ width: 250 }}
                              onChange={(e) => {
                                setInputValue("pray1", i, e.target.value);
                              }}
                            >
                              {menuItems.map(({ label, value }, index) => (
                                <MenuItem key={index} value={value}>
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        )}
                      />
                    </Grid>
                    <Box sx={{ height: 30 }}></Box>
                    <Typography sx={{ fontSize: 18 }}>
                      願いの項目2つ目
                    </Typography>

                    <Grid>
                      <Controller
                        {...register(`apply.${i}.pray2`)}
                        control={control}
                        ref={null}
                        render={({ field }) => (
                          <div>
                            <Select
                              defaultValue="交通安全"
                              fullWidth
                              sx={{ width: 250 }}
                              onChange={(e) => {
                                setInputValue("pray2", i, e.target.value);
                              }}
                            >
                              {menuItems.map(({ label, value }, index) => (
                                <MenuItem key={index} value={value}>
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        )}
                      />
                    </Grid>
                    <Box sx={{ height: 30 }}></Box>
                    <Typography sx={{ fontSize: 18 }}>
                      おふだの種類と大きさ
                    </Typography>
                    <Grid>
                      <Controller
                        {...register(`apply.${i}.money`)}
                        control={control}
                        ref={null}
                        render={({ field }) => (
                          <div>
                            <Select
                              defaultValue="五千円（普通）"
                              fullWidth
                              sx={{ width: 250 }}
                              onChange={(e) => {
                                setInputValue("money", i, e.target.value);
                              }}
                            >
                              {money_menu.map(({ label, value }, index) => (
                                <MenuItem key={index} value={value}>
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        )}
                      />
                    </Grid>
                    <Box sx={{ height: 80 }}></Box>
                  </Grid>
                </Box>
              )
          )}
      </form>  */}
      <Divider style={{ width: "100%" }} />

      <Box sx={{ height: 50 }}></Box>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"center"}
      >
        <Grid container justify="flex-start" alignItems="flex-start">
          <Grid item xs={6}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#ffa94d", color: "white" }}
              onClick={() => goHome()}
            >
              戻る
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#ffa94d", color: "white" }}
              onClick={() => confirmDOpen()}
            >
              申込
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ height: 150 }}></Box>

      <Dialog onClose={retryDClose} open={retryD}>
        <DialogTitle>選択されていません</DialogTitle>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          <Button
            variant="contained"
            color="inherit"
            onClick={() => retryDClose()}
          >
            戻る
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>

      <Dialog onClose={confirmDClose} open={confirmD}>
        <Typography sx={{ fontSize: 18 }} m={2}>
          この内容で予約します、よろしいですか？
        </Typography>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          <Grid container justify="flex-start" alignItems="flex-start" mt={2}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#ffa94d", color: "white" }}
                onClick={confirmDClose}
              >
                戻る
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#ffa94d", color: "white" }}
                onClick={() => onSubmit("next")}
              >
                予約する
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default Gosyuin;
