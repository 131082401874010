import React, {
  useState,
  useContext,
  useLayoutEffect,
  useEffect,
  useRef,
} from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SanpaiDeta from "./SanpaiDeta";

import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  onSnapshot,
  getDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import { useAuthContext } from "../context/ AuthContext";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { DialogContent, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Dialog from "@mui/material/Dialog";
import DenseAppbar from "../ Component/DenseAppbar";
import TukinamiGohei from "../ Component/Sub/TukinamiGohei";
import Divider from "@mui/material/Divider";
import oonusa from "../images/oonusa.png";
import fox from "../images/fox.png";
import shelf from "../images/shelf.png";
import g_torii from "../images/g_torii.png";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import Koyomi from "../DialyComponent/ Component/Koyomi";
import Unsei from "../DialyComponent/ Component/Unsei";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import LineComponent from "../ Component/Sub/LineComponent";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import Skeleton from "@mui/material/Skeleton";
import loading_mov from "../movies/loading.json";
import correct from "../movies/correct.json";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { Player } from "@lottiefiles/react-lottie-player";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import m0 from "../images/m0.png";
import m1 from "../images/m1.png";
import m2 from "../images/m2.png";
import m3 from "../images/m3.png";
import m4 from "../images/m4.png";
import m5 from "../images/m5.png";
import m6 from "../images/m6.png";
import m7 from "../images/m7.png";
import m8 from "../images/m8.png";
import m9 from "../images/m9.png";
import m10 from "../images/m10.png";
import m11 from "../images/m11.png";
import m12 from "../images/m12.png";
import m13 from "../images/m13.png";
import m14 from "../images/m14.png";
import m15 from "../images/m15.png";
import m16 from "../images/m16.png";
import m17 from "../images/m17.png";
import m18 from "../images/m18.png";
import m19 from "../images/m19.png";
import m20 from "../images/m20.png";
import m21 from "../images/m21.png";
import m22 from "../images/m22.png";
import m23 from "../images/m23.png";
import m24 from "../images/m24.png";
import m25 from "../images/m25.png";
import m26 from "../images/m26.png";
import m27 from "../images/m27.png";
import m28 from "../images/m28.png";
import m29 from "../images/m29.png";
import BottomMenu from "../ Component/Sub/BottomMenu";
import MakePWA from "../ Component/Sub/MakePWA.js";
import Contact from "../ Component/Sub/Contact";
import Family from "../ Component/Family";
import { getRedirectResult } from "firebase/auth";
import { GetFieldValues } from "../ Component/QueryHandler/DailyHandler.js";
import Walkthrough from "../ Component/Sub/WalkthroughPage";
import { auth, provider } from "../firebase";
import { motion } from "framer-motion";

export default function AchievePage(props) {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const [sanpai_deta, SetSanpaiDeta] = useState("");
  const [kigan_deta, SetKiganDeta] = useState("");
  const [koyomi_deta, SetKoyomiDeta] = useState("");
  const [account_docid, SetAccountDocid] = useState("");
  const [accounts, setAccounts] = useState("");
  const [document_id, setDocumentId] = useState("");

  const [ohyakudoD, setOhyakudoD] = useState(false);
  const [loginD, setLoginD] = useState(false);
  const [magatama_count, setmagatama_Count] = useState(0);

  const [loading, setLoading] = useState(true);
  const player1 = useRef(null);
  const [lottie, set_lottie] = useState(null);
  const [moon_img, setMoonImage] = useState("");
  const [moon_age, setMoonAge] = useState(0);

  const [db_deta, set_db_deta] = useState(null); //DBのvalues
  const [ex_count, set_ex_count] = useState(0); //経験値

  const [tutorial_dialog, set_tutorial_dialog] = useState(false);

  const [tutorial_complete, set_tutorial_complete] = useState(null);
  const [logincomplete, set_logincomplete] = useState(false);

  const ohyakudoDHandler = (type) => {
    setOhyakudoD(type);
  };

  const goDialy = () => {
    navigate("/dialy");
  };

  useLayoutEffect(() => {
    if (user !== null) {
      GetFirebaseDeta();
    }
  }, []);

  const GetFirebaseDeta = async () => {
    const deta = await GetFieldValues();
    console.log("Firebase Deta" + JSON.stringify(deta));
    set_db_deta(deta);
    set_ex_count(deta["values"][0]["ex"]);

    // if (!CheckTutorialComplete(deta)) {
    //   //チュートリアルDialog表示)
    //   set_tutorial_complete(false);

    //   setTimeout(() => {
    //     HandleTutorialDialog(true);
    //   }, 2000);
    // } else {
    //   set_tutorial_complete(true);
    // }
  };

  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        if (result == null) {
          // console.log("result-> " + result);
        } else {
          //ToDo ログインが完了したダイアログを表示
          HandleLoginComplete(true);
          setTimeout(() => {
            HandleLoginComplete(false);
          }, 3000);
        }
      })
      .catch((error) => {});
  });

  useEffect(() => {
    let isMounted = true;

    const get_baseinfo = async () => {
      //アカウントを検索
      if (user) {
        try {
          const auth = getAuth();
          const current_uid = auth.currentUser.uid;
          const userRef = collection(db, "account_list");

          let docid = "";
          const q = query(userRef, where("uid", "==", current_uid));

          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            docid = doc.id;
            setDocumentId(docid);
          });
          if (isMounted) {
            SetAccountDocid(docid);
            GetSanpaiDeta(docid);
            GetKiganDeta(docid);
            try {
              //アカウント一覧があるsubcollectionのRef
              let index = 0;
              let accounts_values = [""];
              const accountsRef = collection(
                db,
                "account_list",
                docid,
                "accounts"
              );
              const accountsSnaps = await getDocs(accountsRef);
              accountsSnaps.forEach((d) => {
                accounts_values[index] = d.data();
                index += 1;
              });
              setAccounts(accounts_values);
            } catch (e) {
              console.log(e);
            }
          }
        } catch (e) {
          console.log("error: " + e.message);
        }
      } else {
        // console.log("userが見つかりません");
      }
    };

    const GetSanpaiDeta = async (id) => {
      const docRef = doc(db, "account_list", id);
      const docSnap = await getDoc(docRef);
      const values = docSnap.data();

      if (typeof values["dango"] !== "undefined") {
        //マガタマの数
        if (isMounted) {
          setmagatama_Count(values["dango"]);
        }
      } else {
        if (isMounted) {
          setmagatama_Count(0);
        }
      }

      //ここで参拝した日をクエリ
      try {
        let index = 0;
        let sanpai_values = [];
        const sanpaiRef = collection(db, "account_list", id, "sanpai");

        const sanpai_query = query(sanpaiRef, orderBy("day", "asc"));
        const sanpaiSnaps = await getDocs(sanpai_query);
        sanpaiSnaps.forEach((doc) => {
          sanpai_values[index] = doc.data();
          index += 1;
        });

        // console.log("SanpaiDeta: " + JSON.stringify(sanpai_values));
        if (isMounted) {
          if (sanpai_values.length > 0) {
            SetSanpaiDeta(sanpai_values);
          } else {
            console.log("参拝データがありません");
            SetSanpaiDeta("");
          }
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    const GetKiganDeta = async (id) => {
      try {
        let index = 0;
        let kigan_values = [""];
        const kiganRef = collection(db, "account_list", id, "details");
        const kiganSnaps = await getDocs(kiganRef);
        kiganSnaps.forEach((doc) => {
          kigan_values[index] = doc.data();
          index += 1;
        });

        if (isMounted) {
          SetKiganDeta(kigan_values);
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    //月齢から月の画像を指定する
    const getMoon = (age) => {
      const int_age = Math.round(age);

      switch (int_age) {
        case 0:
          setMoonImage(m0);
          break;
        case 1:
          setMoonImage(m1);
          break;
        case 2:
          setMoonImage(m2);
          break;
        case 3:
          setMoonImage(m3);
          break;
        case 4:
          setMoonImage(m4);
          break;
        case 5:
          setMoonImage(m5);
          break;
        case 6:
          setMoonImage(m6);
          break;
        case 7:
          setMoonImage(m7);
          break;
        case 8:
          setMoonImage(m8);
          break;
        case 9:
          setMoonImage(m9);
          break;
        case 10:
          setMoonImage(m10);
          break;
        case 11:
          setMoonImage(m11);
          break;
        case 12:
          setMoonImage(m12);
          break;
        case 13:
          setMoonImage(m13);
          break;
        case 14:
          setMoonImage(m14);
          break;
        case 15:
          setMoonImage(m15);
          break;
        case 16:
          setMoonImage(m16);
          break;
        case 17:
          setMoonImage(m17);
          break;
        case 18:
          setMoonImage(m18);
          break;
        case 19:
          setMoonImage(m19);
          break;
        case 20:
          setMoonImage(m20);
          break;
        case 21:
          setMoonImage(m21);
          break;
        case 22:
          setMoonImage(m22);
          break;
        case 23:
          setMoonImage(m23);
          break;
        case 24:
          setMoonImage(m24);
          break;
        case 25:
          setMoonImage(m25);
          break;
        case 26:
          setMoonImage(m26);
          break;
        case 27:
          setMoonImage(m27);
          break;
        case 28:
          setMoonImage(m28);
          break;
        case 29:
          setMoonImage(m29);
          break;
        default:
          break;
      }
    };

    const getKoyomi = () => {
      const today = new Date();
      var month = ("0" + (today.getMonth() + 1)).slice(-2);
      var today_day = ("0" + today.getDate()).slice(-2);
      var year = today.getFullYear();
      var hour = today.getHours();
      var lat = 37.05292192148423;
      var lon = 140.9398749548493;

      const server = `https://koyomi.zingsystem.com/api/?mode=d&cnt=1&targetyyyy=2023&targetmm=${month}&targetdd=${today_day}`;
      //月齢：月の満ち欠けを新月（さく）から計って日数で表したものです

      const moon_server = `https://mgpn.org/api/moon/position.cgi?json&y=${year}&m=${month}&d=${today_day}&h=${hour}&lat=${lat}&lon=${lon}`;

      axios
        .get(server)
        .then((res) => {
          // console.log(res);
          const deta = res.data;
          const day = Object.keys(deta["datelist"]);
          const rokuyou = deta["datelist"][day]["rokuyou"];
          const inreki = deta["datelist"][day]["inreki"];
          const zyunisi = deta["datelist"][day]["zyunisi"];
          const zyusi = deta["datelist"][day]["zyusi"];
          const sekki = deta["datelist"][day]["sekki"];
          const gengo = deta["datelist"][day]["gengo"];
          const wareki = deta["datelist"][day]["wareki"];
          const eto = deta["datelist"][day]["eto"];
          const now = new Date();
          const k_month = now.getMonth() + 1;
          // console.log("k_month: " + k_month);
          const k_day = now.getDate();
          // console.log("k_day: " + k_day);
          const koyomi_dic = {
            inreki: inreki,
            zyunisi: zyunisi,
            zyusi: zyusi,
            eto: eto,
            gengo: gengo,
            wareki: wareki,
            month: k_month,
            day: k_day,
          };
          SetKoyomiDeta(koyomi_dic);
          // console.log(koyomi_dic);
        })
        .catch(console.error);

      axios
        .get(moon_server)
        .then((res) => {
          const deta = res.data;
          var age = deta["result"]["age"];
          age = age.toFixed(2);
          if (isMounted) {
            setMoonAge(age);
            getMoon(age);
          }
        })
        .catch(console.error);

      // console.log("get was called");
    };
    get_baseinfo();
    getKoyomi();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (lottie) {
      lottie.play();
    }
  }, [lottie]);

  useEffect(() => {
    // console.log("マガタマ: " + magatama_count);
    // console.log("参拝:" + sanpai_deta);
    if (
      //  magatama_count !== null &&
      // sanpai_deta !== "" &&
      koyomi_deta !== "" &&
      moon_img !== "" &&
      moon_age !== 0
    ) {
      setLoading(false);
    }
  }, [magatama_count, sanpai_deta, koyomi_deta, moon_img, moon_age]);

  const get_baseinfo = async (isMounted) => {
    //アカウントを検索
    if (user) {
      try {
        const auth = getAuth();
        const current_uid = auth.currentUser.uid;
        const userRef = collection(db, "account_list");

        let docid = "";
        const q = query(userRef, where("uid", "==", current_uid));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          docid = doc.id;
        });
        if (isMounted) {
          SetAccountDocid(docid);
          GetSanpaiDeta(isMounted, docid);
          GetKiganDeta(isMounted, docid);
        }
      } catch (e) {
        console.log("error: " + e.message);
      }
    } else {
      // console.log("userが見つかりません");
    }
  };

  const GetSanpaiDeta = async (isMounted, id) => {
    const docRef = doc(db, "account_list", id);
    const docSnap = await getDoc(docRef);
    const values = docSnap.data();

    if (typeof values["dango"] !== "undefined") {
      //マガタマの数
      if (isMounted) {
        setmagatama_Count(values["dango"]);
      }
    } else {
      if (isMounted) {
        setmagatama_Count(0);
      }
    }

    //ここで参拝した日をクエリ
    try {
      let index = 0;
      let sanpai_values = [""];
      const sanpaiRef = collection(db, "account_list", id, "sanpai");

      const sanpai_query = query(sanpaiRef, orderBy("day", "asc"));
      const sanpaiSnaps = await getDocs(sanpai_query);
      sanpaiSnaps.forEach((doc) => {
        sanpai_values[index] = doc.data();
        index += 1;
      });

      if (isMounted) {
        SetSanpaiDeta(sanpai_values);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const GetKiganDeta = async (isMounted, id) => {
    try {
      let index = 0;
      let kigan_values = [""];
      const kiganRef = collection(db, "account_list", id, "details");
      const kiganSnaps = await getDocs(kiganRef);
      kiganSnaps.forEach((doc) => {
        kigan_values[index] = doc.data();
        index += 1;
      });

      if (isMounted) {
        SetKiganDeta(kigan_values);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const loginDopen = () => {
    setLoginD(true);
  };
  const loginDclose = () => {
    setLoginD(false);
  };

  const HandleTutorialDialog = (open) => {
    set_tutorial_dialog(open);
  };

  const goSignup = () => {
    navigate("/signup");
  };

  const goAccounts = () => {
    if (user !== null) {
      navigate("/accounts");
    } else {
      loginDopen();
    }
  };

  const HandleLoginComplete = (open) => {
    set_logincomplete(open);
  };

  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();

  if (!loading) {
    return (
      <div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <DenseAppbar
            text="ホーム"
            magatama={magatama_count}
            ex_count={ex_count}
          ></DenseAppbar>

          <Box sx={{ height: 40 }}></Box>
          {/* {user === null && (
            <React.Fragment>
              <Box sx={{ height: 10 }}></Box>
            </React.Fragment>
<<<<<<< HEAD
<<<<<<< HEAD
          )} */}
          {/* {user !== null && <TukinamiGohei></TukinamiGohei>} */}

          {user !== null && <MakePWA />}

          <Grid container alignItems={"center"} justifyContent={"center"}>
            {koyomi_deta !== "" && moon_img !== "" && moon_age !== 0 && (
              <Koyomi
                moon_img={moon_img}
                moon_age={moon_age}
                koyomi_dic={koyomi_deta}
              ></Koyomi>
            )}
            {/* <Box sx={{ height: 40 }}></Box>
            {document_id !== "" && (
              <Unsei
                docid={document_id}
                ex_count={ex_count}
                magatama_count={magatama_count}
                tutorial_complete={tutorial_complete}
                GetFirebaseDeta={() => GetFirebaseDeta()}
              />
            )}
            {document_id == "" && <Unsei docid={document_id} />} */}
            <Box sx={{ height: 40 }}></Box>

            {accounts !== "" && (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                textAlign={"center"}
              >
                <Divider sx={{ width: "90%", mb: 2 }}></Divider>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  textAlign={"left"}
                >
                  <Grid item xs={6}>
                    <Typography
                      sx={{ ml: 3, fontSize: 20, fontWeight: "bold" }}
                    >
                      アカウント一覧
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ height: 20 }}></Box>

                <Card sx={{ width: "90%" }}>
                  <CardContent>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign={"center"}
                    >
                      <Family accounts={accounts}></Family>
                      <Box sx={{ height: 20 }}></Box>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#ffa94d",
                          color: "white",
                          minWidth: "200px",
                          minHeight: 55,
                          width: "60%",
                        }}
                        onClick={() => goAccounts()}
                      >
                        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                          追加・編集する
                        </Typography>
                      </Button>
                    </Grid>
                  </CardContent>
                </Card>
                {/* {accounts == "" && (
            <Skeleton variant="rounded" width={"90%"} height={240} />
          )} */}
              </Grid>
            )}
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              textAlign={"center"}
            >
              <Box sx={{ height: 10 }}></Box>
              <Contact />
              <Box sx={{ height: 120 }}></Box>
            </Grid>

            <Dialog
              onClose={() => ohyakudoDHandler(false)}
              open={ohyakudoD}
              sx={{ width: "100%" }}
            >
              <Grid sx={{ height: "90%" }}>
                <Box sx={{ height: 50 }}></Box>

                <Typography
                  sx={{ fontSize: 17, fontWeight: "bold" }}
                  mr={2}
                  ml={2}
                  mb={4}
                  mt={3}
                >
                  お百度参り（おひゃくどまいり）
                </Typography>
                <Typography sx={{ fontSize: 15 }} mr={2} ml={2} mb={3}>
                  何度もお参りをすることで、願いが成就するとされています。
                </Typography>
                <Typography sx={{ fontSize: 15 }} mr={2} ml={2} mb={3}>
                  日本の歴史書である『吾妻鏡』の記述などから、鎌倉時代初期には既に存在していたことが分かっています。
                </Typography>
                <Typography sx={{ fontSize: 15 }} mr={2} ml={2} mb={1}>
                  古来の日本人と同じように、あなたもお百度参りを目指してみませんか？
                </Typography>
                <Box sx={{ height: 20 }}></Box>

                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  direction="column"
                  textAlign={"center"}
                >
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => ohyakudoDHandler(false)}
                    sx={{ width: "70%", height: 44 }}
                  >
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      もどる
                    </Typography>
                  </Button>
                  <Box sx={{ height: 60 }}></Box>
                </Grid>
              </Grid>
            </Dialog>
            <Dialog onClose={loginDclose} open={loginD} sx={{ width: "100%" }}>
              <Box sx={{ height: 40 }}></Box>
              <Grid p={2}>
                <Typography
                  sx={{ fontSize: 16, fontWeight: "bold" }}
                  mt={2}
                  mr={2}
                  ml={2}
                >
                  ご利用には、新規登録・ログインが必要です
                </Typography>
                <Box sx={{ height: 40 }}></Box>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  direction="column"
                  textAlign={"center"}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#ffa94d",
                      color: "white",
                      width: "70%",
                      height: 40,
                    }}
                    onClick={() => goSignup()}
                  >
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      登録・ログイン
                    </Typography>
                  </Button>
                  <Box sx={{ height: 20 }}></Box>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => loginDclose()}
                    style={{ width: "70%" }}
                  >
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      もどる
                    </Typography>
                  </Button>
                </Grid>
                <Box sx={{ height: 40 }}></Box>
              </Grid>
            </Dialog>

            <Dialog
              onClose={() => HandleLoginComplete(false)}
              open={logincomplete}
              sx={{ width: "100%" }}
            >
              <Box sx={{ height: 20 }}></Box>
              <Grid p={2}>
                <Typography sx={{ fontSize: 22, fontWeight: "bold" }} m={2}>
                  ログインしました
                </Typography>
                <Box sx={{ height: 20 }}></Box>
              </Grid>
            </Dialog>
            {document_id !== "" && (
              <Dialog
                onClose={() => HandleTutorialDialog(false)}
                open={tutorial_dialog}
                fullScreen
              >
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  style={{ height: "100%" }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: "100%" }}
                  >
                    <Walkthrough
                      CloseDialog={() => HandleTutorialDialog(false)}
                      document_id={document_id}
                    />
                  </Grid>
                </motion.div>
              </Dialog>
            )}
          </Grid>
        </motion.div>
        <BottomMenu value={0} />
      </div>
    );
  } else {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress></CircularProgress>
        <BottomMenu value={0} />
      </Box>
    );
  }
}
