import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import { useNavigate, Navigate, Link } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import LaunchIcon from "@mui/icons-material/Launch";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { getFunctions, httpsCallable } from "firebase/functions";
import looking_fox from "../../images/looking_fox.png";

const YakudosiPicker = (props) => {
  const navigate = useNavigate();

  const [value, setValue] = useState(new Date(1993, 11));
  const [inputValue, setInputValue] = useState(1993);
  const [sex_value, setSexValue] = useState(sex[0]);
  const [sex_inputValue, setSexInputValue] = useState("");
  const [yaku, setYaku] = useState("厄年ではありません");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [search, SetSearch] = useState(false);
  const [yakuD, setYakuD] = useState(false);
  const [yakudoshiData, setYakudoshiData] = useState("");
  const [modal_text, setModalText] = useState("");
  const [yaku_text, setYakuText] = useState("");

  const [selectedyear, setSelectedYear] = useState("");
  const [selectedmonth, setSelectedMonth] = useState("4");
  const [selectedday, setSelectedDay] = useState("10");
  const [modalVisible, setModalVisible] = useState(false);

  // 初回レンダリング時にデータを取得
  useEffect(() => {
    console.log("useEffect called");
    fetchYakudoshiData();
  }, []);

  const OutputYaku = () => {
    // console.log(sex_value);
    // console.log(value);
    const year = value.getFullYear();
    const year_text = String(year) + "年";
    let output_yaku = SearchYaku(sex_value, year_text);
    // console.log(year_text + " " + sex_value + ": " + output_yaku);
    if (output_yaku !== "厄年ではありません") {
      output_yaku += "の年です";
      setYaku(output_yaku);
    } else {
      setYaku(output_yaku);
    }
  };

  const handleserach = (type) => {
    if (type === "reset") {
      setValue(new Date(1993, 11));
      setInputValue("");
      setSexValue(sex[0]);
      setSexInputValue("");
      SetSearch(false);
    } else {
      OutputYaku();
      SetSearch(true);
      handleYakuD(true);
    }
  };

  const handleYakuD = (open) => {
    if (!open) {
      setValue(new Date(1993, 11));
      setInputValue("");
      setSexValue(sex[0]);
      setSexInputValue("");
      SetSearch(false);
    }
    setYakuD(open);
  };

  const goApply = () => {
    if (props.accounts !== "") {
      navigate("/newyearyaku");
    } else {
      props.openAccountD();
    }
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiOutlinedInput-input": {
        border: 0,
        borderRadius: 3,
        fontSize: 18,
        fontWeight: "bold",
      },
    },
  }));

  const classes = useStyles();

  const fetchYakudoshiData = async () => {
    const year = new Date().getFullYear();
    try {
      const response = await fetch(
        `https://asia-northeast1-yaku-api.cloudfunctions.net/api/yakudoshi/${
          year + 1
        }`,
        {
          mode: "cors", // CORS対応
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Yakudoshi Data:", data);
      setYakudoshiData(data);
    } catch (err) {
      console.log("error: ", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const serch = (data) => {
    let birthday = "";
    const year = value.getFullYear();

    console.log(year + " " + selectedmonth + " " + selectedday);
    birthday = year + "-" + selectedmonth + "-" + selectedday;

    console.log("selectedSex: " + sex_value);
    console.log("birthday : " + birthday);
    console.log("yakudoshiData : " + yakudoshiData);

    if (sex_value !== "" && birthday !== "" && yakudoshiData !== "") {
      console.log(sex_value + " と " + birthday + " で厄年を取得します");
      //まずは方位除けを取得する
      let houi = "";
      const date = new Date(birthday);
      const year = date.getFullYear();
      const happosai = yakudoshiData.happosai;
      const byomon = yakudoshiData.byomon;
      const kimon = yakudoshiData.kimon;
      if (happosai.includes(String(year))) {
        console.log("「八方塞」です");
        houi = "八方塞";
      } else if (byomon.includes(String(year))) {
        console.log("「病門」です");
        houi = "病門";
      } else if (kimon.includes(String(year))) {
        console.log("「鬼門」です");
        houi = "鬼門";
      }
      //性別に応じて厄年を取得する
      let yaku = "";
      const man_preyaku = yakudoshiData.maleYakudoshi.preYakudoshi;
      console.log("man_preyaku: " + man_preyaku);
      const man_honyaku = yakudoshiData.maleYakudoshi.honYakudoshi;
      console.log("man_honyaku: " + man_honyaku);
      const man_postyaku = yakudoshiData.maleYakudoshi.postYakudoshi;
      console.log("man_postyaku: " + man_postyaku);

      const woman_preyaku = yakudoshiData.femaleYakudoshi.preYakudoshi;
      const woman_honyaku = yakudoshiData.femaleYakudoshi.honYakudoshi;
      const woman_postyaku = yakudoshiData.femaleYakudoshi.postYakudoshi;

      if (sex_value === "男性") {
        if (man_preyaku.includes(String(year))) {
          console.log("男性：「前厄」です");
          yaku = "前厄";
        } else if (man_honyaku.includes(String(year))) {
          console.log("男性：「本厄」です");
          yaku = "本厄";
        } else if (man_postyaku.includes(String(year))) {
          console.log("男性：「後厄」です");
          yaku = "後厄";
        }
      } else if (sex_value === "女性") {
        if (woman_preyaku.includes(String(year))) {
          console.log("女性：「前厄」です");
          yaku = "前厄";
        } else if (woman_honyaku.includes(String(year))) {
          console.log("女性：「本厄」です");
          yaku = "本厄";
        } else if (woman_postyaku.includes(String(year))) {
          console.log("女性：「後厄」です");
          yaku = "後厄";
        }
      }

      if (houi !== "" && yaku !== "") {
        //方位と厄年にどちらも該当している場合
        console.log(
          `${year}年生まれの${sex_value}は「${houi}」と「${yaku}」の年です`
        );
        setModalText(
          `${year}年生まれの${sex_value}は「${houi}」と「${yaku}」の年です`
        );
        setYakuText(`${houi}と${yaku}`);
      } else if (houi !== "") {
        //方位に該当している場合
        console.log(`${year}年生まれの${sex_value}は「${houi}」の年です`);
        setModalText(`${year}年生まれの${sex_value}は「${houi}」の年です`);
        setYakuText(`${houi}`);
      } else if (yaku !== "") {
        //厄年に該当している場合
        console.log(`${year}年生まれの${sex_value}は「${yaku}」の年です`);
        setModalText(`${year}年生まれの${sex_value}は、「${yaku}」の年です`);
        setYakuText(`${yaku}`);
      } else {
        setModalText("厄年や方位に該当していません");
        setYakuText("");
      }

      setModalVisible(true);
    }
  };

  return (
    <React.Fragment>
      <Card sx={{ width: "90%" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
          textAlign={"left"}
        >
          {/* <Box sx={{ height: 10 }}></Box> */}

          {/* {search && (
            <Box sx={{ width: "90%" }}>
              <Alert severity="success">
                <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                  {yaku}
                </Typography>
              </Alert>
            </Box>
          )}
          {!search && (
            <Box sx={{ width: "90%" }}>
              <Alert severity="info">
                <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                  検索を押すと表示されます
                </Typography>
              </Alert>
            </Box>
          )} */}

          <Grid
            style={{ backgroundColor: "white", width: "100%" }}
            p={2}
            container
            alignItems="center"
            justifyContent="center"
            direction="column"
            textAlign={"left"}
          >
            <Box sx={{ height: 10 }}></Box>

            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={5.5}>
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }} mb={1}>
                  生まれた年
                </Typography>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  textAlign={"center"}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ja}
                  >
                    <DatePicker
                      views={["year"]}
                      value={value}
                      showToolbar={false}
                      inputValue={inputValue + "年"}
                      onChange={(newValue) => {
                        setValue(newValue);
                      }}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      sx={{ fontSize: 16, fontWeight: "bold" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoFocus={false}
                          className={classes.root}
                          error={false}
                          // inputProps={{
                          //   sx: { fontSize: 16, fontWeight: "bold" },
                          // }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={5.5}>
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }} mb={1}>
                  性別
                </Typography>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  textAlign={"center"}
                >
                  <Autocomplete
                    value={sex_value}
                    onChange={(event, newValue) => {
                      setSexValue(newValue);
                    }}
                    inputValue={sex_inputValue}
                    onInputChange={(event, newInputValue) => {
                      setSexInputValue(newInputValue);
                    }}
                    id="sex"
                    options={sex}
                    sx={{ width: "100%", backgroundColor: "white" }}
                    renderInput={({ inputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        autoFocus={false}
                        className={classes.root}
                        inputProps={{
                          ...inputProps,
                          readOnly: true,
                          // style: { fontSize: 16, fontWeight: "bold" },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ height: 20 }}></Box>

            <Grid container sx={{ width: "100%" }}>
              {/* <Grid item xs={6}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  textAlign={"center"}
                >
                  <Button
                    sx={{
                      width: 120,
                      height: 55,
                    }}
                    color="inherit"
                    variant="contained"
                    onClick={() => handleserach("reset")}
                  >
                    <Typography
                      sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
                    >
                      リセット
                    </Typography>
                  </Button>
                </Grid>
              </Grid> */}
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  textAlign={"center"}
                >
                  <Button
                    sx={{
                      width: "60%",
                      height: 55,
                      backgroundColor: "#ffa94d",
                    }}
                    variant="contained"
                    onClick={() => serch()}
                  >
                    <Typography
                      sx={{ fontSize: 18, fontWeight: "bold", color: "white" }}
                    >
                      検索
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ height: 10 }}></Box>
        </Grid>
      </Card>

      <Dialog
        open={modalVisible}
        fullWidth
        onClose={() => setModalVisible(false)}
      >
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          direction={"column"}
          p={2}
        >
          <Box sx={{ height: 40 }}></Box>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            direction="row"
          >
            {/* <Grid item container justifyContent={"flex-end"} xs={4}>
              <img src={looking_fox} width="70"></img>
            </Grid> */}

            <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 3 }}>
              {modal_text}
            </Typography>
          </Grid>
          <Box sx={{ height: 40 }}></Box>
          {yaku !== "厄年ではありません" && (
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              mb={2}
            >
              <Button
                sx={{ height: 55, width: "70%", backgroundColor: "#ffa94d" }}
                variant="contained"
                onClick={() => goApply()}
              >
                <Typography
                  sx={{ fontSize: 16, fontWeight: "bold", color: "white" }}
                >
                  厄祓いのおふだを申し込む
                </Typography>
              </Button>
            </Grid>
          )}

          <Button
            sx={{ width: "90%", height: 55, backgroundColor: "#ece9e9f2" }}
            onClick={() => setModalVisible(false)}
            variant="contained"
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", color: "black" }}
            >
              もどる
            </Typography>
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};

const sex = ["男性", "女性"];
const year = [
  "1923年",
  "1924年",
  "1925年",
  "1926年",
  "1927年",
  "1928年",
  "1929年",
  "1930年",
  "1931年",
  "1932年",
  "1933年",
  "1934年",
  "1935年",
  "1936年",
  "1937年",
  "1938年",
  "1939年",
  "1940年",
  "1941年",
  "1942年",
  "1943年",
  "1944年",
  "1945年",
  "1946年",
  "1947年",
  "1948年",
  "1949年",
  "1950年",
  "1951年",
  "1952年",
  "1953年",
  "1954年",
  "1955年",
  "1956年",
  "1957年",
  "1958年",
  "1959年",
  "1960年",
  "1961年",
  "1962年",
  "1963年",
  "1964年",
  "1965年",
  "1966年",
  "1967年",
  "1968年",
  "1969年",
  "1970年",
  "1971年",
  "1972年",
  "1973年",
  "1974年",
  "1975年",
  "1976年",
  "1977年",
  "1978年",
  "1979年",
  "1980年",
  "1981年",
  "1982年",
  "1983年",
  "1984年",
  "1985年",
  "1986年",
  "1987年",
  "1988年",
  "1989年",
  "1990年",
  "1991年",
  "1992年",
  "1993年",
  "1994年",
  "1995年",
  "1996年",
  "1997年",
  "1998年",
  "1999年",
  "2000年",
  "2001年",
  "2002年",
  "2003年",
  "2004年",
  "2005年",
  "2006年",
  "2007年",
  "2008年",
  "2009年",
  "2010年",
  "2011年",
  "2012年",
  "2013年",
  "2014年",
  "2015年",
  "2016年",
  "2017年",
  "2018年",
  "2019年",
  "2020年",
  "2021年",
  "2022年",
  "2023年",
];

const SearchYaku = (sex, year) => {
  let yaku = "厄年ではありません";

  //八方塞
  switch (year) {
    case "1925年":
      yaku = "八方塞";
      break;
    case "1934年":
      yaku = "八方塞";
      break;

    case "1943年":
      yaku = "八方塞";
      break;

    case "1952年":
      yaku = "八方塞";
      break;

    case "1961年":
      yaku = "八方塞";
      break;
    case "1970年":
      yaku = "八方塞";
      break;
    case "1979年":
      yaku = "八方塞";
      break;
    case "1988年":
      yaku = "八方塞";
      break;
    case "1997年":
      yaku = "八方塞";
      break;
    case "2006年":
      yaku = "八方塞";
      break;
    case "2015年":
      yaku = "八方塞";
      break;
    case "2024年":
      yaku = "八方塞";
      break;
    default:
      break;
  }

  //鬼門
  switch (year) {
    case "1922年":
      yaku = "鬼門";
      break;
    case "1931年":
      yaku = "鬼門";
      break;
    case "1940年":
      yaku = "鬼門";
      break;
    case "1949年":
      yaku = "鬼門";
      break;
    case "1958年":
      yaku = "鬼門";
      break;
    case "1967年":
      yaku = "鬼門";
      break;
    case "1976年":
      yaku = "鬼門";
      break;
    case "1985年":
      yaku = "鬼門";
      break;
    case "1994年":
      yaku = "鬼門";
      break;
    case "2003年":
      yaku = "鬼門";
      break;
    case "2012年":
      yaku = "鬼門";
      break;
    case "2021年":
      yaku = "鬼門";
      break;
    default:
      break;
  }

  //病門
  switch (year) {
    case "1919年":
      yaku = "病門";
      break;
    case "1928年":
      yaku = "病門";
      break;
    case "1937年":
      yaku = "病門";
      break;
    case "1946年":
      yaku = "病門";
      break;
    case "1955年":
      yaku = "病門";
      break;
    case "1964年":
      yaku = "病門";
      break;
    case "1973年":
      yaku = "病門";
      break;
    case "1982年":
      yaku = "病門";
      break;
    case "1991年":
      yaku = "病門";
      break;
    case "2000年":
      yaku = "病門";
      break;
    case "2009年":
      yaku = "病門";
      break;
    case "2018年":
      yaku = "病門";
      break;

    default:
      break;
  }

  //男性の厄年
  if (sex === "男性") {
    switch (year) {
      case "1923年":
        break;
      case "1924年":
        break;
      case "1925年":
        break;
      case "1926年":
        break;
      case "1927年":
        break;
      case "1928年":
        break;
      case "1929年":
        break;
      case "1930年":
        break;
      case "1931年":
        break;
      case "1932年":
        break;
      case "1933年":
        break;
      case "1934年":
        break;
      case "1935年":
        break;
      case "1936年":
        break;
      case "1937年":
        break;
      case "1938年":
        break;
      case "1939年":
        break;
      case "1940年":
        break;
      case "1941年":
        break;
      case "1942年":
        break;
      case "1943年":
        break;
      case "1944年":
        break;
      case "1945年":
        break;
      case "1946年":
        break;
      case "1947年":
        break;
      case "1948年":
        break;
      case "1949年":
        break;
      case "1950年":
        break;
      case "1951年":
        break;
      case "1952年":
        break;
      case "1953年":
        break;
      case "1954年":
        break;
      case "1955年":
        break;
      case "1956年":
        break;
      case "1957年":
        break;
      case "1958年":
        break;
      case "1959年":
        break;
      case "1960年":
        break;
      case "1961年":
        break;
      case "1962年":
        break;
      case "1963年":
        yaku = "後厄";
        break;
      case "1964年":
        yaku = "本厄";
        break;
      case "1965年":
        yaku = "前厄";
        break;
      case "1966年":
        break;
      case "1967年":
        break;
      case "1968年":
        break;
      case "1969年":
        break;
      case "1970年":
        break;
      case "1971年":
        break;
      case "1972年":
        break;
      case "1973年":
        break;
      case "1974年":
        break;
      case "1975年":
        break;
      case "1976年":
        break;
      case "1977年":
        break;
      case "1978年":
        break;
      case "1979年":
        break;
      case "1980年":
        break;
      case "1981年":
        break;
      case "1982年":
        yaku = "後厄";
        break;
      case "1983年":
        yaku = "本厄";
        break;
      case "1984年":
        yaku = "前厄";
        break;
      case "1985年":
        break;
      case "1986年":
        break;
      case "1987年":
        break;
      case "1988年":
        break;
      case "1989年":
        break;
      case "1990年":
        break;
      case "1991年":
        break;
      case "1992年":
        break;
      case "1993年":
        break;
      case "1994年":
        break;
      case "1995年":
        break;
      case "1996年":
        break;
      case "1997年":
        break;
      case "1998年":
        break;
      case "1999年":
        yaku = "後厄";
        break;
      case "2000年":
        yaku = "本厄";
        break;
      case "2001年":
        yaku = "前厄";
        break;
      case "2002年":
        break;
      case "2003年":
        break;
      case "2004年":
        break;
      case "2005年":
        break;
      case "2006年":
        break;
      case "2007年":
        break;
      case "2008年":
        break;
      case "2009年":
        break;
      case "2010年":
        break;
      case "2011年":
        break;
      case "2012年":
        break;
      case "2013年":
        break;
      case "2014年":
        break;
      case "2015年":
        break;
      case "2016年":
        break;
      case "2017年":
        break;
      case "2018年":
        break;
      case "2019年":
        break;
      case "2020年":
        break;
      case "2021年":
        break;
      case "2022年":
        break;
      case "2023年":
        break;

      default:
        break;
    }
  } else {
    //女性の厄年
    switch (year) {
      case "1923年":
        break;
      case "1924年":
        break;
      case "1925年":
        break;
      case "1926年":
        break;
      case "1927年":
        break;
      case "1928年":
        break;
      case "1929年":
        break;
      case "1930年":
        break;
      case "1931年":
        break;
      case "1932年":
        break;
      case "1933年":
        break;
      case "1934年":
        break;
      case "1935年":
        break;
      case "1936年":
        break;
      case "1937年":
        break;
      case "1938年":
        break;
      case "1939年":
        break;
      case "1940年":
        break;
      case "1941年":
        break;
      case "1942年":
        break;
      case "1943年":
        break;
      case "1944年":
        break;
      case "1945年":
        break;
      case "1946年":
        break;
      case "1947年":
        break;
      case "1948年":
        break;
      case "1949年":
        break;
      case "1950年":
        break;
      case "1951年":
        break;
      case "1952年":
        break;
      case "1953年":
        break;
      case "1954年":
        break;
      case "1955年":
        break;
      case "1956年":
        break;
      case "1957年":
        break;
      case "1958年":
        break;
      case "1959年":
        break;
      case "1960年":
        break;
      case "1961年":
        break;
      case "1962年":
        break;
      case "1963年":
        yaku = "後厄";
        break;
      case "1964年":
        yaku = "本厄";
        break;
      case "1965年":
        yaku = "前厄";
        break;
      case "1966年":
        break;
      case "1967年":
        break;
      case "1968年":
        break;
      case "1969年":
        break;
      case "1970年":
        break;
      case "1971年":
        break;
      case "1972年":
        break;
      case "1973年":
        break;
      case "1974年":
        break;
      case "1975年":
        break;
      case "1976年":
        break;
      case "1977年":
        break;
      case "1978年":
        break;
      case "1979年":
        break;
      case "1980年":
        break;
      case "1981年":
        break;
      case "1982年":
        break;
      case "1983年":
        break;
      case "1984年":
        break;
      case "1985年":
        break;
      case "1986年":
        break;
      case "1987年":
        yaku = "後厄";
        break;
      case "1988年":
        yaku = "本厄";
        break;
      case "1989年":
        yaku = "前厄";
        break;
      case "1990年":
        break;
      case "1991年":
        yaku = "後厄";
        break;
      case "1992年":
        yaku = "本厄";
        break;
      case "1993年":
        yaku = "前厄";
        break;
      case "1994年":
        break;
      case "1995年":
        break;
      case "1996年":
        break;
      case "1997年":
        break;
      case "1998年":
        break;
      case "1999年":
        break;
      case "2000年":
        break;
      case "2001年":
        break;
      case "2002年":
        break;
      case "2003年":
        break;
      case "2004年":
        break;
      case "2005年":
        yaku = "後厄";
        break;
      case "2006年":
        yaku = "本厄";
        break;
      case "2007年":
        yaku = "前厄";
        break;
      case "2008年":
        break;
      case "2009年":
        break;
      case "2010年":
        break;
      case "2011年":
        break;
      case "2012年":
        break;
      case "2013年":
        break;
      case "2014年":
        break;
      case "2015年":
        break;
      case "2016年":
        break;
      case "2017年":
        break;
      case "2018年":
        break;
      case "2019年":
        break;
      case "2020年":
        break;
      case "2021年":
        break;
      case "2022年":
        break;
      case "2023年":
        break;

      default:
        break;
    }
  }

  //年祝い
  switch (year) {
    case "1926年":
      yaku = "白寿";
      break;
    case "1935年":
      yaku = "卒寿";
      break;
    case "1937年":
      yaku = "米寿";
      break;
    case "1945年":
      yaku = "傘寿";
      break;
    case "1948年":
      yaku = "喜寿";
      break;
    case "1955年":
      yaku = "古稀";
      break;
    case "1964年":
      yaku = "還暦";
      break;
    default:
      break;
  }

  return yaku;
};

export default YakudosiPicker;
